import { createRouter } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';

// Set up a ReactRouter instance
export const router = createRouter({
  routeTree,
});

// Register things for typesafety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
