import React, { MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import EmojiPickerReact, { EmojiClickData } from 'emoji-picker-react';
import clsx from 'clsx';

import { useOnClickOutside } from '../hooks/useOnClickOutside';

export type EmojiPickerWithButtonProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  onChange?: (data: string) => void;
  value?: string;
  customContent?: React.ReactNode;
  onOpenChange?: (to: boolean) => void;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  emojiPickerProps?: React.PropsWithChildren<any>;
  pickerOpenSide?: 'left' | 'right';
  disableOutsideClose?: boolean;
};

export const EmojiPickerWithButton = ({
  emojiPickerProps,
  onChange,
  value,
  buttonProps,
  onOpenChange,
  pickerOpenSide,
  disableOutsideClose = false,
  customContent,
  ...props
}: EmojiPickerWithButtonProps) => {
  const [valueState, setValueState] = useState(value ?? '');

  const handleEmojiChange = (emojiData: EmojiClickData) => {
    setValueState(emojiData.emoji);
    onChange && onChange(emojiData.emoji);
    // emojiPickerProps?.onEmojiClick?.(event, data);
  };

  const valueAbs = useMemo(() => {
    return value ?? valueState;
  }, [value, valueState]);

  const [openState, setOpenState] = useState(false);

  const wrapperRef = useRef(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const handleOutsideClick = useCallback(() => {
    if (!disableOutsideClose) {
      setOpenState(false);
      onOpenChange?.(false);
    }
  }, [setOpenState, onOpenChange, disableOutsideClose]);

  const handleToggleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setOpenState(!openState);
  };

  useOnClickOutside(wrapperRef, handleOutsideClick);

  return (
    <div {...props} className={clsx('irui-relative', props.className)} ref={wrapperRef}>
      <button
        {...buttonProps}
        className={clsx(
          'irui-text-4xl irui-h-12 irui-w-12',
          valueAbs === '' && 'irui-border irui-rounded irui-border-solid irui-border-newgray-400',
          buttonProps?.className,
        )}
        onClick={handleToggleOpen}
      >
        {props?.children ?? customContent ?? valueAbs}
      </button>
      <div
        style={{
          top: `calc(${buttonContainerRef?.current?.getBoundingClientRect?.()?.height}px + 12px)`,
        }}
        className={clsx(
          pickerOpenSide === 'left' ? 'irui-right-0' : 'irui-left-0',
          'irui-absolute irui-overflow-hidden irui-shadow irui-z-50',
        )}
      >
        <div>{openState && <EmojiPickerReact {...emojiPickerProps} onEmojiClick={handleEmojiChange} />}</div>
      </div>
    </div>
  );
};
