import {
  createElement,
  ButtonHTMLAttributes,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import clsx from 'clsx';
import { IconBaseProps } from 'react-icons';

export type CheckboxProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;
  disabled?: boolean;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  checked?: boolean;
  onChange?: (to: boolean) => void;
  label?: string;
  labelPosition?: 'left' | 'right';
  iconProps?: IconBaseProps;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
};

export const Checkbox = ({
  inputProps,
  labelProps,
  disabled,
  label,
  checked,
  labelPosition = 'right',
  onChange,
  iconProps,
  buttonProps,
  ...props
}: CheckboxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [checkedState, setCheckedState] = useState(inputProps?.checked ?? checked ?? false);

  const absChecked = useMemo(() => {
    return inputProps?.checked ?? checked ?? checkedState;
  }, [inputProps, checked, checkedState]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedState(!absChecked);
      onChange?.(!absChecked);
      inputProps?.onChange?.(e);
    },
    [inputProps, onChange, setCheckedState, absChecked],
  );

  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      inputRef?.current?.click();
      buttonProps?.onClick?.(e);
    },
    [inputRef, buttonProps],
  );

  const labelElem = (
    <label
      {...labelProps}
      onClick={(e) => {
        labelProps?.onClick?.(e);
        inputRef?.current?.click();
      }}
      className={clsx(
        disabled || inputProps?.disabled ? 'irui-text-newgray-400' : 'irui-text-newgray-900 dark:irui-text-white',
        labelPosition === 'left' && 'irui-mr-2',
        labelPosition === 'right' && 'irui-ml-2',
        'irui-font-sans-serif irui-transition irui-duration-500',
        labelProps?.className,
      )}
    >
      {labelProps?.children ?? label}
    </label>
  );

  return (
    <div {...props} className={clsx('irui-flex irui-items-center', props.className)}>
      {labelPosition === 'left' && labelElem}
      <input
        {...inputProps}
        ref={inputRef}
        disabled={inputProps?.disabled ?? disabled}
        className={clsx('irui-hidden', inputProps?.className)}
        type="checkbox"
        checked={absChecked}
        onChange={handleChange}
      />
      <button
        {...buttonProps}
        className={clsx(
          absChecked ? 'irui-text-newgreen-400' : 'irui-text-newgray-400',
          'irui-p-0 irui-border-0',
          buttonProps?.className,
        )}
        onClick={handleButtonClick}
      >
        {createElement(absChecked ? MdCheckBox : MdCheckBoxOutlineBlank, {
          ...iconProps,
          size: iconProps?.size ?? 20,
        })}
      </button>

      {labelPosition === 'right' && labelElem}
    </div>
  );
};
