import { useCallback, useMemo, useRef, useState } from 'react';
import { animated } from 'react-spring';
import clsx from 'clsx';
import { ChromePicker, ChromePickerProps, ColorResult } from 'react-color';

import { useHeightAnimation } from '../../hooks/useHeightAnimation';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

export type ColorPickerProps = React.HTMLAttributes<HTMLDivElement> & {
  open?: boolean;
  onOpenChange?: (to: boolean) => void;
  value?: string;
  onValueChange?: (value: string) => void;
  pickerProps?: ChromePickerProps;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  pickerOpenSide?: 'left' | 'right';
  disableOutsideClose?: boolean;
};

export const ColorPicker = ({
  open,
  onOpenChange,
  value,
  onValueChange,
  pickerProps,
  buttonProps,
  disableOutsideClose,
  pickerOpenSide = 'right',
  ...props
}: ColorPickerProps) => {
  const [valueState, setValueState] = useState(value ?? '#111');
  const [openState, setOpenState] = useState(false);

  const wrapperRef = useRef(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const handleOutsideClick = useCallback(() => {
    if (!disableOutsideClose) {
      setOpenState(false);
      onOpenChange?.(false);
    }
  }, [setOpenState, onOpenChange, disableOutsideClose]);

  useOnClickOutside(wrapperRef, handleOutsideClick);

  const openAbs = useMemo(() => {
    return open ?? openState;
  }, [open, openState]);

  const valueAbs = useMemo(() => {
    return pickerProps?.color?.toString() ?? value ?? valueState;
  }, [value, valueState, pickerProps]);

  const handleToggleOpen = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setOpenState(!openAbs);
      onOpenChange?.(!openAbs);
      buttonProps?.onClick?.(e);
    },
    [buttonProps, onOpenChange, setOpenState, openAbs],
  );

  const handleValueChange = useCallback(
    (c: ColorResult, e: React.ChangeEvent<HTMLInputElement>) => {
      pickerProps?.onChange?.(c, e);
      // Handle HEX colors
      if (value?.startsWith('#')) {
        setValueState(c?.hex);
        onValueChange?.(c?.hex);
        return;
        // Handle HSL colors
      } else if (value?.toLowerCase()?.startsWith('hsl')) {
        let hslString;
        if (c?.hsl?.a && c?.hsl?.a !== 1) {
          hslString = `hsla(${Math.round(c?.hsl?.h)}, ${Math.round(c?.hsl?.s * 100)}%, ${Math.round(
            c?.hsl?.l * 100,
          )}%, ${c?.hsl?.a})`;
        } else {
          hslString = `hsl(${Math.round(c?.hsl?.h)}, ${Math.round(c?.hsl?.s * 100)}%, ${Math.round(c?.hsl?.l * 100)}%)`;
        }
        setValueState(hslString);
        onValueChange?.(hslString);
        return;
        // Handle RGB colors
      } else {
        let rgbString;
        if (c?.rgb?.a !== undefined && c?.rgb?.a !== 1) {
          rgbString = `rgba(${c?.rgb?.r}, ${c?.rgb?.g}, ${c?.rgb?.b}, ${c?.rgb?.a})`;
        } else {
          rgbString = `rgb(${c?.rgb?.r}, ${c?.rgb?.g}, ${c?.rgb?.b})`;
        }
        setValueState(rgbString);
        onValueChange?.(rgbString);
      }
    },
    [pickerProps, setValueState, onValueChange, value],
  );

  const { expand, contentRef } = useHeightAnimation(openAbs, { duration: 200 });
  return (
    <div {...props} ref={wrapperRef} className={clsx('irui-relative irui-w-full irui-h-full', props.className)}>
      <div
        ref={buttonContainerRef}
        style={{ backgroundColor: valueAbs }}
        className="irui-inline-flex irui-h-full irui-w-full"
      >
        <button
          {...buttonProps}
          className={clsx('irui-w-full irui-h-full irui-flex irui-cursor-pointer', buttonProps?.className)}
          onClick={handleToggleOpen}
        />
      </div>

      <animated.div
        style={{
          ...expand,
          top: `calc(${buttonContainerRef?.current?.getBoundingClientRect?.()?.height}px + 12px)`,
        }}
        className={clsx(
          pickerOpenSide === 'left' ? 'irui-right-0' : 'irui-left-0',
          'irui-absolute irui-overflow-hidden irui-shadow irui-z-50',
        )}
      >
        <div ref={contentRef}>
          <ChromePicker {...pickerProps} color={valueAbs} onChange={handleValueChange} />
        </div>
      </animated.div>
    </div>
  );
};
