import clsx from 'clsx';
import { useMemo } from 'react';

export type DividedSectionProps = React.HTMLAttributes<HTMLDivElement> & {
  borderTop?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
};

export const DividedSection = ({
  borderTop = false,
  borderBottom = false,
  borderLeft = false,
  borderRight = false,
  ...props
}: DividedSectionProps) => {
  const paddingInStyle = useMemo(() => {
    const s = props.style;
    return s?.padding || s?.paddingLeft || s?.paddingRight || s?.paddingTop || s?.paddingBottom;
  }, [props]);
  return (
    <div
      {...props}
      className={clsx(
        !paddingInStyle && 'irui-p-8',
        'irui-border-newgray-300 irui-border-solid',
        borderRight && 'irui-border-r',
        borderLeft && 'irui-border-l',
        borderBottom && 'irui-border-b',
        borderTop && 'irui-border-t',
        props.className,
      )}
    />
  );
};
