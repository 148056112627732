/* eslint-disable react-hooks/rules-of-hooks */
import { createFileRoute } from '@tanstack/react-router';
import { checkThatVideosyncTokenExistsAndIsNotExpired } from '@/utils';
import { videosyncOldUrl } from '@/config';

export const Route = createFileRoute('/event-admin/$accountName/$eventId')({
  beforeLoad: ({ navigate }) => {
    // check token
    try {
      checkThatVideosyncTokenExistsAndIsNotExpired();
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === 'Token not found') {
          // redirect to login
          const currentUrl = window.location.href;
          window.location.href = videosyncOldUrl + '/login?redirect=' + currentUrl;
        }
      }

      // TODO: keep the redirect in the url
      // redirect: router.currentUrl.pathname,
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw navigate({ to: '/login', search: { errorCode: 'token-expired' } });
    }
  },
});
