import clsx from 'clsx';

export type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  fullsize?: boolean;
};

export const Card = ({ fullsize, children, ...props }: CardProps) => {
  return (
    <div
      {...props}
      className={clsx(
        fullsize && 'irui-w-full irui-h-full',
        'irui-rounded-lg irui-border irui-border-solid border-newgray-400',
        props.className,
      )}
    >
      {children}
    </div>
  );
};
