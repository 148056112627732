import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { AnimationResult, Controller, SpringValue, useSpring } from 'react-spring';

export const useHeightAnimation = (
  open: boolean,
  ops?: {
    duration?: number;
    onChange?: (result: AnimationResult<SpringValue>, spring: Controller<any>) => void;
  },
) => {
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  const contentRef = useRef<any>(null);

  useLayoutEffect(() => {
    const ro = new ResizeObserver(([entry], observer) => {
      setContentHeight(entry.contentRect.height);
    });
    if (contentRef.current) {
      ro.observe(contentRef.current);
    }
    return () => {
      ro.disconnect();
    };
  }, []);

  const [shouldOverflow, setShouldOverflow] = useState(open);

  // Isimmediate state to disable animations on mount
  const [isImmediate, setIsImmediate] = useState(true);

  const expand = useSpring({
    immediate: isImmediate,
    height: open ? `${contentHeight ?? contentRef?.current?.getBoundingClientRect()?.height}px` : '0px',
    onChange: (result, spring) => {
      const currH = parseFloat(result.value.height);
      if (shouldOverflow !== (currH === contentHeight)) {
        setShouldOverflow(currH === contentHeight);
      }
      if (isImmediate && currH === contentHeight && open) {
        setIsImmediate(false);
      }
      if (isImmediate && !open) {
        setIsImmediate(false);
      }
      ops?.onChange?.(result, spring);
    },
    config: {
      duration: ops?.duration ?? 500,
    },
  });

  useEffect(() => {
    if (isImmediate && !open) {
      // Can always set to false since "closed" elemnts dont create a flash
      setIsImmediate(false);
    }
  }, [isImmediate, open]);

  useEffect(() => {
    if (!contentHeight) {
      setContentHeight(contentRef?.current?.getBoundingClientRect().height);
    }
    const set = () => {
      if (contentRef.current) setContentHeight(contentRef.current.getBoundingClientRect().height);
    };

    //Adds resize event listener
    window.addEventListener('resize', set);

    // Clean-up
    return () => window.removeEventListener('resize', set);
  }, [contentHeight]);

  return {
    contentRef,
    expand,
    shouldOverflow,
  };
};
