import { ChangeEvent, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import styled from 'styled-components';

import { Label } from '../Label';

interface PasswordInputProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  label?: string;
}

type EyeIconProps = {
  showPassword: boolean;
  onClick?: () => void;
};

export const PasswordInput = ({ placeholder, value, onChange, label }: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="irui-relative">
      {label && <Label className="mb-8">{label}</Label>}
      <input
        className="irui-w-full irui-p-2 irui-border irui-rounded-sm irui-text-base irui-font-sans-serif irui-outline-none focus:irui-outline-none irui-bg-transparent irui-py-3.5 irui-px-5 placeholder-newgray-500"
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        autoComplete="off"
        data-1p-ignore
        data-lpignore="true"
      />
      <EyeIcon showPassword={showPassword} onClick={handleToggleVisibility} />
    </div>
  );
};

const EyeIcon = styled(({ showPassword, ...props }: EyeIconProps) =>
  showPassword ? <AiOutlineEyeInvisible {...props} /> : <AiOutlineEye {...props} />,
)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;
