import { LinkButton, Title } from '@inderes/videosync-ui-components';
import { useSearch } from '@tanstack/react-router';

import { RootContainer } from '../components/Layout/RootContainer';

export const Login = () => {
  const search = useSearch({ from: "/login"});
  const oldVideosyncUrl: string = import.meta.env.BACKSTAGE_OLD_VIDEOSYNC_URL;
  const loginUrl = oldVideosyncUrl + '/login';

  if (search?.redirect) {
    window.location.href = search.redirect;
  }

  return (
    <RootContainer>
      {/* centered container  */}
      <div className="flex h-screen items-center justify-center">
        <div className="w-96">
          {/* login button */}
          <div className="mb-4 flex flex-col items-center justify-center">
            <Title className="mb-4 text-center !text-white">Go to login page</Title>
            <LinkButton className="m-auto" variant="secondary" href={loginUrl}>
              Log in
            </LinkButton>
          </div>
        </div>
      </div>
    </RootContainer>
  );
};
