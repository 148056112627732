import { useCallback, useMemo, useState } from 'react';
import { format, parse, isValid as isValidDate } from 'date-fns';
import clsx from 'clsx';

import { Datepicker, DatepickerProps } from '../../Inputs/Datepicker';
import { TextInput, TextInputProps } from '../TextInput';

import './TextInputWithDatePickerHoverStyle.css';

type TextInputWithDatepickerProps = {
  startDate?: Date | null;
  endDate?: Date | null;
  daterange?: boolean;
  onDateChange?: (startDate: Date | null, endDate: Date | null) => void;
  openOnFocus?: boolean;
  textInputProps?: TextInputProps;
  datepickerProps?: DatepickerProps;
};

// Uses date format dd.MM.yyyy

export const TextInputWithDatepicker = ({
  startDate,
  endDate,
  onDateChange,
  daterange = false,
  openOnFocus = true,
  textInputProps,
  datepickerProps,
}: TextInputWithDatepickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [startDateState, setStartDateState] = useState<Date | null>(startDate || null);
  const [endDateState, setEndDateState] = useState(endDate || null);

  const [startDateTextValue, setStartDateTextValue] = useState(startDate ? format(startDate, 'dd.MM.yyyy') : '');
  const [endDateTextValue, setEndDateTextValue] = useState(endDate ? format(endDate, 'dd.MM.yyyy') : '');

  const startDateAbs = useMemo(() => {
    return startDate ?? startDateState;
  }, [startDate, startDateState]);

  const endDateAbs = useMemo(() => {
    return endDate ?? endDateState;
  }, [endDate, endDateState]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (daterange || datepickerProps?.daterange) {
        const start = value.split(' - ')[0];
        const end = value.split(' - ')[1];
        setStartDateTextValue(start);
        setEndDateTextValue(end);
      } else {
        setStartDateTextValue(value);
      }

      textInputProps?.onChange?.(e);
    },
    [setStartDateTextValue, setEndDateTextValue, textInputProps, daterange, datepickerProps],
  );

  const handleDatepickerChange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      setStartDateState(startDate);
      setEndDateState(endDate);
      onDateChange?.(startDate, endDate);
      startDate ? setStartDateTextValue(format(startDate, 'dd.MM.yyyy')) : setStartDateTextValue('');
      endDate ? setEndDateTextValue(format(endDate, 'dd.MM.yyyy')) : setEndDateTextValue('');
    },
    [onDateChange, setStartDateState, setEndDateState, setStartDateTextValue, setEndDateTextValue],
  );

  const handleInputFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    if (openOnFocus && !isOpen) {
      setIsOpen(true);
    }
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    // Check that focus is not a child
    if (e.relatedTarget === null || !e?.currentTarget.contains(e.relatedTarget as any)) {
      textInputProps?.onBlur?.(e);
      const startParsed = parse(startDateTextValue, 'dd.MM.yyyy', new Date());
      const newStartDate = isValidDate(startParsed) ? startParsed : startDateAbs;
      const endParsed = parse(endDateTextValue, 'dd.MM.yyyy', new Date());
      const newEndDate =
        isValidDate(endParsed) && endParsed.getTime() > (newStartDate?.getTime() ?? 0) ? endParsed : endDateAbs;
      handleDatepickerChange(newStartDate, newEndDate);
      if (openOnFocus && isOpen) {
        setIsOpen(false);
      }
    }
  };

  const textInputValue = useMemo(() => {
    if (daterange || datepickerProps?.daterange) {
      return startDateTextValue + ' - ' + endDateTextValue;
    }
    return startDateTextValue;
  }, [startDateTextValue, endDateTextValue, datepickerProps, daterange]);

  return (
    <TextInput
      {...textInputProps}
      value={textInputValue}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      onFocus={handleInputFocus}
      onKeyDown={(e) => {
        textInputProps?.onKeyDown?.(e);
        if (e.key === 'Enter') {
          handleInputBlur(e as any);
        }
      }}
      containerProps={{
        ...textInputProps?.containerProps,
        className: clsx('hover-helper-1', textInputProps?.containerProps?.className),
      }}
      sideItem={
        <Datepicker
          {...datepickerProps}
          className="input-datepicker"
          daterange={datepickerProps?.daterange ?? daterange ?? false}
          pickerOpenSide="left"
          startDate={startDateAbs}
          endDate={endDateAbs}
          onDateChange={handleDatepickerChange}
          open={isOpen}
          onOpenChange={setIsOpen}
          textInputOpenOnFocus={openOnFocus}
          buttonContainerProps={{
            ...datepickerProps?.buttonContainerProps,
            className: clsx('hover-helper-2', datepickerProps?.buttonContainerProps?.className),
          }}
        />
      }
    />
  );
};
