import clsx from 'clsx';

type RowProps = React.HTMLAttributes<HTMLDivElement> & {
  wrap?: boolean;
};

export const Row = ({ wrap, ...props }: RowProps) => {
  return (
    <div {...props} className={clsx(wrap && 'irui-flex-wrap', 'irui-flex irui-w-full irui-gap-4', props.className)} />
  );
};
