'use client';

import { Provider, createStore } from 'jotai';
import { DevTools as JotaiDevTools } from 'jotai-devtools';

export const store = createStore();

// TODO: import this conditionally only in dev mode
import 'jotai-devtools/styles.css';

export const JotaiProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Provider store={store}>
      {children}
      <JotaiDevTools position="bottom-right" theme="dark" store={store} />
    </Provider>
  );
};
