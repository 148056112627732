import clsx from 'clsx';
import { Label, LabelProps } from '../Label';
import { useCallback, useMemo, useRef, useState } from 'react';

export type TextareaProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  fullwidth?: boolean;
  resizable?: boolean;
  error?: boolean;
  label?: string;
  value?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  textareaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  labelProps?: LabelProps;
};

export const Textarea = ({
  fullwidth,
  resizable = true,
  error,
  label,
  textareaProps,
  labelProps,
  value,
  onChange,
  placeholder,
  disabled,
  containerProps,
  ...props
}: TextareaProps) => {
  const [valueState, setValueState] = useState(textareaProps?.value ?? value ?? '');

  const valueAbs = useMemo(() => {
    return textareaProps?.value ?? value ?? valueState;
  }, [textareaProps, value, valueState]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const v = e.target.value;
      setValueState(v);
      onChange?.(e);
      textareaProps?.onChange?.(e);
    },
    [onChange, textareaProps, setValueState],
  );

  return (
    <div {...props} className={clsx('irui-inline-flex irui-flex-col', fullwidth && 'irui-w-full', props.className)}>
      {(labelProps?.children || label) && (
        <Label
          {...labelProps}
          disabled={textareaProps?.disabled ?? disabled}
          error={error}
          inputRef={textareaRef}
          inputId={textareaProps?.id ?? undefined}
        >
          {labelProps?.children ?? label}
        </Label>
      )}
      <div
        {...containerProps}
        className={clsx(
          'irui-rounded irui-border irui-border-solid irui-border-newgray-400 irui-overflow-hidden irui-w-full',
          !resizable && 'irui-h-64',
          containerProps?.className,
        )}
      >
        <textarea
          {...textareaProps}
          placeholder={textareaProps?.placeholder ?? placeholder}
          disabled={textareaProps?.disabled ?? disabled}
          value={valueAbs}
          onChange={handleChange}
          className={clsx(
            'irui-w-full irui-h-full focus:irui-outline-none irui-p-4 irui-leading-[24px]',
            resizable ? '!irui-min-h-[4rem] irui-resize-y irui-flex' : 'irui-resize-none',
            textareaProps?.className,
          )}
        />
      </div>
    </div>
  );
};
