import clsx from 'clsx';
import { useMemo } from 'react';
import { IconBaseProps } from 'react-icons';
import { CgSpinner } from 'react-icons/cg';

type LoaderSpinnerProps = IconBaseProps & {
  size?: 'xs' | 'sm' | 'sme' | 'md' | 'lg' | 'xl';
};

export const LoaderSpinner = ({ size = 'md', ...props }: LoaderSpinnerProps) => {
  const sizeClass = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'irui-w-2 irui-h-2';
      case 'sm':
        return 'irui-w-3 irui-h-3';
      case 'sme':
        return 'irui-w-4 irui-h-4';
      case 'md':
        return 'irui-w-6 irui-h-6';
      case 'lg':
        return 'irui-w-8 irui-h-8';
      case 'xl':
        return 'irui-w-12 irui-h-12';
    }
  }, [size]);
  return (
    <CgSpinner {...props} className={clsx('irui-animate-spin irui-text-newgreen-400', sizeClass, props.className)} />
  );
};
