import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';


export const Route = createFileRoute('/web-studio/$eventId')({
  validateSearch: z.object({
    code: z.string(),
    advanced: z.number().optional(),
    studio: z.number().optional(),
  }),
});
