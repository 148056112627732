import { trpc } from '../../hooks/trpc';
import { Card, CardTitle } from '@inderes/videosync-ui';

export const SystemAdminHome = () => {
  const { data: calendarData } = trpc.systemAdmin.calendarData.useQuery();

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 mb-4 flex justify-between pt-8">
        <h1 className="text-3xl font-semibold text-zinc-400 ">Home</h1>
      </div>

      <Card className="col-span-6">
        <p className="mb-3 text-lg font-semibold">Something very informative</p>
        <p>Moro</p>
      </Card>

      <Card className="col-span-6 min-h-[300px]">
        <CardTitle>Event feedback</CardTitle>
        <div className="h-[300px] w-full">{/* <SimpleChart /> */}aa</div>
      </Card>

      <Card className="col-span-6 min-h-[300px]">
        <CardTitle>Event feedback</CardTitle>
        <div className="h-[300px] w-full">{/* <NivoLineChart /> */}</div>
      </Card>

      <Card className="col-span-12">
        <p className="mb-3 text-lg font-semibold text-zinc-400">Nice graph</p>
        <p>📈 📉</p>
      </Card>

      <Card className="col-span-12">
        <p className="mb-3 text-lg font-semibold text-zinc-400">event-site</p>
        {calendarData?.map((event) => (
          <a
            href={`http://${event.account?.urlName}.videosync.localhost:3006/${event.urlName}`}
            key={event.id}
            target="_blank"
            rel="noreferrer"
          >
            {event.title}
          </a>
        ))}
      </Card>
    </div>
  );
};
