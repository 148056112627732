import { useRef } from 'react';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { AiOutlineClose } from 'react-icons/ai';

import { useOnClickOutside } from '../hooks/useOnClickOutside';
import './modal-animations.css';

export type ModalProps = React.HTMLAttributes<HTMLDivElement> & {
  backgroundProps?: React.HTMLAttributes<HTMLDivElement>;
  contentProps?: React.HTMLAttributes<HTMLDivElement>;
  closeButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  open: boolean;
  onCloseClick: () => void;
  content?: React.ReactNode;
};

export const Modal = ({
  open,
  onCloseClick,
  backgroundProps,
  closeButtonProps,
  contentProps,
  content,
  ...props
}: ModalProps) => {
  const modalContentRef = useRef(null);
  const modalWrapperRef = useRef(null);
  useOnClickOutside(modalContentRef, onCloseClick);

  return (
    <CSSTransition in={open} nodeRef={modalWrapperRef} classNames="modal" unmountOnExit timeout={300}>
      <div
        {...props}
        ref={modalWrapperRef}
        className={clsx(
          'irui-fixed irui-w-screen irui-h-screen irui-top-0 irui-bottom-0 irui-left-0 irui-right-0 irui-flex irui-items-center irui-justify-center irui-z-50',
          props.className,
        )}
      >
        <div
          {...backgroundProps}
          className={clsx(
            'irui-fixed irui-h-full irui-w-full irui-top-0 irui-left-0 irui-bottom-0 irui-right-0 irui-opacity-60 irui-bg-newgray-600 irui-z-10',
            backgroundProps?.className,
          )}
        />
        <div
          {...contentProps}
          ref={modalContentRef}
          className={clsx(
            'irui-z-20 irui-bg-white dark:irui-bg-black irui-relative irui-shadow-xl irui-rounded-lg',
            contentProps?.className,
          )}
        >
          <button
            {...closeButtonProps}
            className={clsx(
              'irui-absolute irui-top-2 irui-right-2 irui-text-newgray-900 dark:irui-text-white',
              closeButtonProps?.className,
            )}
            onClick={onCloseClick}
          >
            <AiOutlineClose />
          </button>
          {contentProps?.children ?? props.children ?? content}
        </div>
      </div>
    </CSSTransition>
  );
};
