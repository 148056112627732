import { createFileRoute, redirect } from '@tanstack/react-router';
import { APP_ENV, appEnv } from '@/config';

export const Route = createFileRoute('/')({
  beforeLoad: () => {
    if (appEnv === APP_ENV.local) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        to: '/system-admin/accounts',
      });
    }
  },
});
