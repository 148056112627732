import clsx from 'clsx';

import { ButtonBaseProps } from './button-types';
import { useButtonClasses } from './useButtonClasses';

type LinkButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & ButtonBaseProps;

export const LinkButton = ({
  children,
  className,
  large = false,
  variant = 'primary',
  fullwidth = false,
  ...props
}: LinkButtonProps) => {
  const buttonClasses = useButtonClasses({
    large,
    variant,
    fullwidth,
  });
  return (
    <a {...props} className={clsx(buttonClasses, 'irui-inline-flex irui-items-center irui-justify-center', className)}>
      {children}
    </a>
  );
};
