import { useMemo } from 'react';

export type ColorOption = 'primary' | 'secondary' | 'default';

export const useTextColorClasses = (colorOption: ColorOption) => {
  const colorClasses = useMemo(() => {
    switch (colorOption) {
      case 'default':
        return 'irui-text-newgray-900 dark:irui-text-white ';
      case 'primary':
        return 'irui-text-newgreen-200 dark:irui-text-newgreen-100';
      case 'secondary':
        return 'irui-text-red-600';
    }
  }, [colorOption]);
  return colorClasses;
};
