import clsx from 'clsx';

export type TableHeadCellProps = React.DetailedHTMLProps<
  React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
  HTMLTableHeaderCellElement
>;

export const TableHeadCell = ({ ...props }: TableHeadCellProps) => {
  return (
    <th
      {...props}
      className={clsx(
        'irui-text-left irui-p-2 irui-bg-newgray-200 irui-border irui-border-solid irui-border-newgray-400 irui-text-sm irui-font-normal',
        props.className,
      )}
    />
  );
};
