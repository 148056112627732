export const APP_ENV = {
  local: 'local',
  development: 'development',
  staging: 'staging',
  production: 'production',
};

// resolve the current appEnv, and default to 'local'
export const appEnv = import.meta.env.BACKSTAGE_APP_ENV || APP_ENV.local;
export const videosyncCdn: string =
  import.meta.env.BACKSTAGE_VIDEOSYNC_CDN || import.meta.env.BACKSTAGE_OLD_VIDEOSYNC_URL;
export const videosyncOldUrl: string = import.meta.env.BACKSTAGE_OLD_VIDEOSYNC_URL;

// This is not a secret, it's just a key to identify the app in Zendesk
export const zendeskWidgetKey = import.meta.env.BACKSTAGE_ZENDESK_WIDGETKEY || '7d222b00-727a-4f2f-8674-8b01b329bbb3';

// Use cloudlfare tunnel if defined (needed for RMP video playback)
export const videosyncCdnTunnel = import.meta.env.CLOUDFLARE_TUNNEL_URL_VIDEOSYNC_CDN || import.meta.env.BACKSTAGE_VIDEOSYNC_CDN;

export const inderesTextToSpeechConfig = {
  baseUrl: 'https://inderes-tts-prod.appspot.com/',
  token: '55b13fa2-db74-49d3-a158-10e99e08f626',
};
