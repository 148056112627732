import clsx from 'clsx';
import { useEffect, useRef, useState, Children, isValidElement, cloneElement } from 'react';
import Sortable, { SortableOptions } from 'sortablejs';

import './sortable-styles.css';

// docs https://github.com/SortableJS/Sortable

type SortableListProps = React.HTMLAttributes<HTMLDivElement> & {
  options?: SortableOptions;
  onEndIds?: (ids: string[]) => void;
};

export const SortableList = ({ options, children, onEndIds, ...props }: SortableListProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [sortable, setSortable] = useState<Sortable | null>(null);
  useEffect(() => {
    if (rootRef.current && !sortable) {
      setSortable(
        Sortable.create(rootRef.current, {
          ...options,
          animation: options?.animation ?? 150,
          ghostClass: options?.ghostClass ?? 'irui-bg-newblue-100',
          onStart: (evt) => {
            // document?.documentElement.classList.add("dragging-cursor");
            options?.onStart?.(evt);
          },
          onEnd: (evt) => {
            // document?.documentElement.classList.remove("dragging-cursor");
            options?.onEnd?.(evt);
            const ids = Array.from(evt.to.children).map((c) => c.id);
            onEndIds?.(ids);
          },
          onMove: (evt, originalEvent) => {
            options?.onMove?.(evt, originalEvent);
          },
        }),
      );
    }
  }, [onEndIds, options, sortable]);

  return (
    <div {...props} ref={rootRef}>
      {Children.toArray(children).map((child) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            ...child.props,
            className: clsx('pre-drag-cursor select-none', child.props.className),
          });
        }
        return child;
      })}
    </div>
  );
};
