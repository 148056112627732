import clsx from 'clsx';
import React, { forwardRef, useImperativeHandle, useEffect, useMemo, useRef, useState } from 'react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import styled from 'styled-components';

/* Helper to resolve the coordinates where the touch event occurred */
const resolveTouchXY = (event: TouchEvent) => {
  let x, y;
  if (
    event.type == 'touchstart' ||
    event.type == 'touchmove' ||
    event.type == 'touchend' ||
    event.type == 'touchcancel'
  ) {
    x = event.touches[0]?.clientX;
    y = event.touches[0]?.clientY;
  }
  return { x, y };
};

/* Helper to resolve the coordinates where the mouse event occurred */
const resolveMouseXY = (event: MouseEvent) => {
  let x, y;
  if (
    event.type == 'mousedown' ||
    event.type == 'mouseup' ||
    event.type == 'mousemove' ||
    event.type == 'mouseover' ||
    event.type == 'mouseout' ||
    event.type == 'mouseenter' ||
    event.type == 'mouseleave'
  ) {
    x = event.clientX;
    y = event.clientY;
  }
  return { x, y };
};

/* TODO: not accurate yet! */
export interface CarouselProps {
  itemsCount: number;
  itemMarginWidth?: number;
  itemWidth?: number;
  children: React.ReactNode;
  carouselPrevIcon?: React.ReactNode;
  carouselNextIcon?: React.ReactNode;
  carouselWrapperClassName?: string;
  carouselButtonClassName?: string;
  carouselButtonIconClassName?: string;
  carouselLeftButtonContainerClassName?: string;
  carouselRightButtonContainerClassName?: string;
  onCarouselScroll?: (scrollPosition: number) => void;
}

interface MousePos {
  x: number | undefined;
  y: number | undefined;
}

interface CarouselItemsContainerProps {
  readonly dragging: boolean;
  readonly currentScrollPosition: number;
  readonly transitionActive: boolean;
}

const CarouselItemsContainer = styled.div.attrs((props: any) => ({
  className: `irui-flex irui-flex-nowrap irui-items-center ${props.dragging ? 'dragging' : ''}`,
  style: {
    transform: `translateX(${props.currentScrollPosition}px)`,
  },
}))<CarouselItemsContainerProps>`
  user-select: none;
  transition: ${(props: CarouselItemsContainerProps) =>
    props.transitionActive
      ? 'transform 0.5s ease-in-out, background-color 0.4s ease-in-out'
      : 'background-color 0.4s ease-in-out'};
`;

// eslint-disable-next-line react/display-name
export const Carousel = forwardRef(
  (
    {
      itemsCount,
      itemMarginWidth = 12,
      itemWidth = 92,
      children,
      carouselNextIcon,
      carouselPrevIcon,
      carouselWrapperClassName,
      carouselButtonClassName,
      carouselButtonIconClassName,
      carouselLeftButtonContainerClassName,
      carouselRightButtonContainerClassName,
      onCarouselScroll,
      ...props
    }: CarouselProps,
    ref,
  ) => {
    const lengthWithMargin = itemMarginWidth + itemWidth;

    const numOfItemsWithMargin = itemsCount - 1;

    const [dragging, setDragging] = useState(false);
    const [carouselButtonInterval, setCarouselButtonInterval] = useState<any>(null); // Should be NodeJS.Timer
    const [mousePos, setMousePos] = useState<MousePos | null>(null);
    const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
    const [clickDisabled, setClickDisabled] = useState(false);
    const carouselWrapperRef = useRef<HTMLDivElement>(null);
    const carouselRef = useRef<HTMLDivElement>(null);
    const [transitionActive, setTransitionActive] = useState(true);
    const [draggingDisabled, setDraggingDisabled] = useState(false);

    /* Check if device uses a touch screen */
    const isTouchEnabled = useMemo(() => {
      if (typeof window === 'undefined') {
        return false;
      }
      return (
        'ontouchstart' in window || (navigator as any).maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0
      );
    }, []);

    /* Decide whether to use mouse or touch events */
    const resolveXY = useMemo(() => {
      if (isTouchEnabled) return resolveTouchXY;
      return resolveMouseXY;
    }, [isTouchEnabled]);

    /* Calculate carousel width */
    const carouselWidth = useMemo(() => {
      return lengthWithMargin * numOfItemsWithMargin + itemWidth;
    }, [lengthWithMargin, numOfItemsWithMargin, itemWidth]);

    const carouselContainerWidth = carouselWrapperRef?.current?.clientWidth || 0;

    /* Check if the carousel is at the end */
    const reachedCarouselEnd = useMemo(() => {
      /* Handle situation where slides do not fill the whole carousel width */
      if (carouselWidth < carouselContainerWidth) {
        return true;
      }
      return -(carouselWidth - carouselContainerWidth) >= currentScrollPosition;
    }, [carouselContainerWidth, carouselWidth, currentScrollPosition]);

    /* Check if the carousel is at the start */
    const atCarouselBeginning = useMemo(() => {
      return currentScrollPosition === 0;
    }, [currentScrollPosition]);

    /* Check scrolling boundaries */
    const checkScrollingBounds = (newScrollPosition: number) => {
      let t = newScrollPosition;

      /* Width of all items in the slider minus width of the visible carousel */
      const carouselWidthWithTransformOffset = carouselWidth - carouselContainerWidth;

      // Reached end
      if (newScrollPosition < -carouselWidthWithTransformOffset) {
        t = -carouselWidthWithTransformOffset;
      }
      // Reached start when transform is 0
      if (newScrollPosition > 0) {
        t = 0;
      }

      // If not enough content to fill wrapper
      if (carouselWidth <= carouselContainerWidth) {
        t = 0;
      }
      return t;
    };

    /* Handles scrolling one carousel item */
    useImperativeHandle(ref, () => ({
      scrollCarouselByOneItem(slideIndex: number) {
        /* Width of the area that is being scrolled is carouselContainerWidth */
        /* Width of the element containing all the slides */
        const containerElemWidth = carouselRef?.current?.clientWidth || 0;
        /* Calculates how much needs to be scrolled depending on the slide index – 122 is one slide width that should be calculated in v3 */
        const transformValue = slideIndex * (itemWidth + itemMarginWidth) - carouselContainerWidth / 2;
        /* This prevents scrolling the first visible half of slides */
        if (containerElemWidth - transformValue > carouselContainerWidth) {
          /* Prevent negative transformValues, use value 0 to scroll to the beginning */
          if (transformValue <= 0) {
            /* Handle edge case where user has manually scrolled the carousel forward and activates the first few slides later on */
            setCurrentScrollPosition(0);
          }
          setCurrentScrollPosition(Math.min(0, -transformValue));
        } else {
          /* This handles scrolling of the last few slides and prevents scrolling past last slide */
          setCurrentScrollPosition(Math.max(-containerElemWidth + carouselContainerWidth, -transformValue));
        }
      },
    }));

    const disableClickTimeout = useRef<number | null>(null);
    // Cleanup
    useEffect(() => {
      const d = disableClickTimeout.current;
      return () => {
        if (d) {
          clearTimeout(d);
        }
      };
    }, []);

    useEffect(() => {
      if (onCarouselScroll) {
        onCarouselScroll(currentScrollPosition);
      }
    }, [currentScrollPosition, onCarouselScroll]);

    /* Drag function */
    const drag = (direction: -1 | 1, speed: number) => {
      setCurrentScrollPosition((c) => {
        const newScrollPosition = checkScrollingBounds(c + direction * speed);
        return newScrollPosition;
      });
    };

    /* Handle mouse down -> start drag */
    const dragStartEvent = (event: MouseEvent & TouchEvent) => {
      setMousePos(resolveXY(event));
      setDragging(true);
      setTransitionActive(false);

      // If user drags for 1 sec, disable changing slide on drag end
      if (disableClickTimeout.current) {
        clearTimeout(disableClickTimeout.current);
      }

      disableClickTimeout.current = window.setTimeout(() => {
        setClickDisabled(true);
      }, 200);
    };

    /* Handle mouse up and mouse leave -> end drag */
    const dragEndEvent = () => {
      setDragging(false);
      setTransitionActive(true);
      setMousePos(null);

      if (disableClickTimeout.current) {
        clearTimeout(disableClickTimeout.current);
      }
      disableClickTimeout.current = window.setTimeout(() => {
        setClickDisabled(false);
      }, 100);
    };

    /* Handle mouse move -> drag */
    const dragEvent = (event: MouseEvent & TouchEvent) => {
      if (draggingDisabled) {
        return;
      }
      if (dragging && !!mousePos) {
        const difference = event.clientX - (mousePos.x || 0);
        if (Math.abs(difference) < 2) {
          return;
        }
        const direction = difference < 0 ? -1 : 1;

        drag(direction, Math.abs(difference));
        setMousePos(resolveXY(event));
      }
    };

    /* Handle carousel button mouse down -> scroll carousel */
    const carouselButtonMouseDown = (direction: -1 | 1) => {
      setDraggingDisabled(true);
      setCarouselButtonInterval(
        setInterval(() => {
          setTransitionActive(true);
          drag(direction, 10);
          setTransitionActive(false);
        }, 10),
      );
    };

    /* Handle carousel button mouse up -> stop scrolling carousel */
    const carouselButtonMouseUp = () => {
      setDraggingDisabled(false);
      clearInterval(carouselButtonInterval);
      setTransitionActive(false);
    };

    /**
     * Provides props for carousel children
     * - "clickDisabled" - prevents clicking on carousel items while dragging
     * - "setTransitionActive" - enables transition when carousel is moving
     */
    const childrenWithProps = React.Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a typescript error too.
      if (React.isValidElement(child)) {
        // TODO: Fix this any. Typescript 4.8 started yelling about the clone element props not being known.
        return React.cloneElement(child, { clickDisabled, setTransitionActive } as any);
      }
      return child;
    });

    return (
      <div className="irui-relative irui-flex irui-w-full">
        <div
          className={clsx(
            'irui-absolute irui-z-50 irui-left-[-10px] irui-bottom-0 irui-top-0 irui-inline-flex irui-items-center irui-justify-center',
            carouselLeftButtonContainerClassName,
            atCarouselBeginning && 'irui-hidden',
          )}
        >
          <button
            onMouseUp={carouselButtonMouseUp}
            onMouseLeave={carouselButtonMouseUp}
            onMouseDown={() => carouselButtonMouseDown(1)}
            className={clsx(
              'irui-border irui-border-solid irui-rounded-full irui-h-8 irui-w-8 irui-p-1 irui-bg-white irui-border-gray-300 irui-text-green-400',
              carouselButtonClassName,
            )}
          >
            <div
              className={clsx(
                'irui-flex irui-h-full irui-w-full irui-items-center irui-justify-center irui-text-[16px]',
                carouselButtonIconClassName,
              )}
            >
              {carouselPrevIcon || <IoChevronBackOutline />}
            </div>
          </button>
        </div>
        <div
          ref={carouselWrapperRef}
          onMouseDown={(event: any) => dragStartEvent(event)}
          onMouseUp={dragEndEvent}
          onMouseLeave={dragEndEvent}
          onMouseMove={(event: any) => dragEvent(event)}
          style={{
            overflowX: isTouchEnabled ? 'auto' : 'hidden',
          }}
          className={clsx(
            'irui-flex irui-flex-1 irui-overflow-hidden irui-relative irui-w-full irui-flex-shrink-0 irui-min-w-0 irui-transition-all irui-duration-500 irui-ease-in-out irui-z-40',
            carouselWrapperClassName,
            dragging && 'dragging',
          )}
        >
          <CarouselItemsContainer
            ref={carouselRef}
            transitionActive={transitionActive}
            dragging={dragging}
            currentScrollPosition={currentScrollPosition}
          >
            {childrenWithProps}
          </CarouselItemsContainer>
        </div>
        <div
          className={clsx(
            'irui-absolute irui-z-50 irui-right-[-10px] irui-bottom-0 irui-top-0 irui-inline-flex irui-items-center irui-justify-center',
            carouselRightButtonContainerClassName,
            reachedCarouselEnd && 'irui-hidden',
          )}
        >
          <button
            onMouseUp={carouselButtonMouseUp}
            onMouseLeave={carouselButtonMouseUp}
            onMouseDown={() => carouselButtonMouseDown(-1)}
            className={clsx(
              'irui-border irui-border-solid irui-rounded-full irui-h-8 irui-w-8 irui-p-1 irui-bg-white irui-border-gray-300 irui-text-green-400',
              carouselButtonClassName,
            )}
          >
            <div
              className={clsx(
                'irui-flex irui-h-full irui-w-full irui-items-center irui-justify-center irui-text-[16px]',
                carouselButtonIconClassName,
              )}
            >
              {carouselNextIcon || <IoChevronForwardOutline />}
            </div>
          </button>
        </div>
      </div>
    );
  },
);
