import { useCallback, useMemo, useRef, useState } from 'react';
import {
  useDatepicker,
  UseDatepickerProps,
  START_DATE,
  OnDatesChangeProps,
  FocusedInput,
  UseMonthProps,
} from '@datepicker-react/hooks';
import { AiOutlineCalendar } from 'react-icons/ai';
import clsx from 'clsx';
import { animated } from 'react-spring';

import { DatepickerContext } from './DatepickerContext';
import { Calendar, CalendarProps } from './Calendar';
import { useHeightAnimation } from '../../hooks/useHeightAnimation';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

export type DatepickerProps = React.HTMLAttributes<HTMLDivElement> & {
  open?: boolean;
  onOpenChange?: (to: boolean) => void;
  startDate?: Date | null;
  endDate?: Date | null;
  onDateChange?: (startDate: Date | null, endDate: Date | null) => void;
  daterange?: boolean;
  datepickerProps?: UseDatepickerProps;
  buttonContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  calendarProps?: CalendarProps;
  disableOutsideClose?: boolean;
  pickerOpenSide?: 'left' | 'right';
  useMonthProps?: Partial<UseMonthProps>;
  textInputOpenOnFocus?: boolean;
};

export const Datepicker = ({
  open,
  onOpenChange,
  datepickerProps,
  startDate,
  endDate,
  onDateChange,
  daterange,
  buttonContainerProps,
  buttonProps,
  calendarProps,
  disableOutsideClose,
  pickerOpenSide = 'right',
  useMonthProps,
  textInputOpenOnFocus,
  ...props
}: DatepickerProps) => {
  const [openState, setOpenState] = useState(open ?? false);
  const openAbs = useMemo(() => {
    return open ?? openState;
  }, [open, openState]);

  const handleOpen = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!textInputOpenOnFocus) {
        setOpenState(!openAbs);
        onOpenChange?.(!openAbs);
      }
      buttonProps?.onClick?.(e);
    },
    [textInputOpenOnFocus, buttonProps, openAbs, onOpenChange],
  );

  const [startDateState, setStartDateState] = useState(startDate ?? null);
  const [endDateState, setEndDateState] = useState(endDate ?? null);
  const [focusedInput, setFocusedInput] = useState<FocusedInput>(START_DATE);

  const wrapperRef = useRef(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = useCallback(() => {
    if (!disableOutsideClose && !textInputOpenOnFocus) {
      setOpenState(false);
      onOpenChange?.(false);
    }
  }, [disableOutsideClose, textInputOpenOnFocus, onOpenChange]);

  useOnClickOutside(wrapperRef, handleOutsideClick);

  const startDateAbs = useMemo(() => {
    return datepickerProps?.startDate ?? startDate ?? startDateState ?? null;
  }, [datepickerProps, startDate, startDateState]);

  const endDateAbs = useMemo(() => {
    if (daterange) {
      return datepickerProps?.endDate ?? endDate ?? endDateState ?? null;
    }
    return null;
  }, [datepickerProps, endDate, endDateState, daterange]);

  const handleChange = useCallback(
    (data: OnDatesChangeProps) => {
      if (daterange) {
        setEndDateState(data.endDate);
        onDateChange?.(data.startDate, data.endDate);
        setFocusedInput(data.focusedInput ?? START_DATE);
      } else {
        onDateChange?.(data.startDate, null);
        setFocusedInput(START_DATE);
      }

      setStartDateState(data.startDate);
    },
    [onDateChange, setStartDateState, setEndDateState, setFocusedInput, daterange],
  );

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    ...datepickerProps,
    startDate: startDateAbs,
    endDate: endDateAbs,
    focusedInput,
    onDatesChange: handleChange,
    numberOfMonths: datepickerProps?.numberOfMonths ?? 2,
    minBookingDays: daterange ? datepickerProps?.minBookingDays ?? 1 : 1,
    exactMinBookingDays: daterange ? datepickerProps?.exactMinBookingDays ?? false : true,
  });

  const providerValue = {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  };
  const { expand, contentRef } = useHeightAnimation(openAbs, { duration: 200 });
  return (
    <DatepickerContext.Provider value={providerValue}>
      <div {...props} className={clsx('irui-relative irui-w-full irui-h-full', props.className)} ref={wrapperRef}>
        <div
          {...buttonContainerProps}
          className={clsx(
            'irui-inline-flex irui-h-full irui-w-full irui-bg-newgreen-200 irui--my-px irui-ml-px irui-border-b irui-border-r irui-border-t irui-border-newgreen-200 irui-border-l-0 irui-rounded-r-sm',
            buttonContainerProps?.className,
          )}
          ref={buttonContainerRef}
          style={{ height: 'calc(100% + 2px)' }}
        >
          <button
            {...buttonProps}
            onClick={handleOpen}
            className={clsx(
              'irui-w-full irui-h-full irui-flex irui-cursor-pointer irui-justify-center irui-items-center irui-p-3',
              buttonProps?.className,
            )}
          >
            <AiOutlineCalendar className="irui-w-full irui-h-full irui-text-white" />
          </button>
        </div>
        <animated.div
          style={{
            ...expand,
            top: `calc(${buttonContainerRef?.current?.getBoundingClientRect?.()?.height}px + 12px)`,
          }}
          className={clsx(
            pickerOpenSide === 'left' ? 'irui-right-0' : 'irui-left-0',
            'irui-absolute irui-overflow-hidden irui-shadow irui-z-50',
          )}
        >
          <div ref={contentRef} className="irui-bg-white dark:irui-bg-black">
            <Calendar {...calendarProps} useMonthProps={useMonthProps} />
          </div>
        </animated.div>
      </div>
    </DatepickerContext.Provider>
  );
};
