'use client';

import { useEffect, useState } from 'react';
import { ChromePicker, Color } from 'react-color';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

declare module 'react-color' {
  export interface ChromePickerProps {
    defaultView?: string;
  }
}

type Props = {
  colorCssVariable: string;
};

export const ColorPicker = (props: Props) => {
  const [color, setColor] = useState<Color | undefined>();
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const onChangeColor = (color: any, event: any) => {
    document.documentElement.style.setProperty(
      props.colorCssVariable,
      `${color.hsl.h} ${color.hsl.s * 100}% ${color.hsl.l * 100}%`,
    );
    setColor(color);
  };

  type HSLColor = {
    a?: number | undefined;
    h: number;
    l: number;
    s: number;
  };

  useEffect(() => {
    const initialDocumentColor = getComputedStyle(document.documentElement)
      .getPropertyValue(props.colorCssVariable)
      .replace('%', '');

    // convert to HSLColor
    const initialColor: HSLColor = {
      h: parseInt(initialDocumentColor.split(' ')[0]),
      l: parseInt(initialDocumentColor.split(' ')[1]),
      s: parseInt(initialDocumentColor.split(' ')[2]),
      a: 1,
    };
    setColor(initialColor);
  }, [props.colorCssVariable]);

  return (
    <div>
      <Popover>
        <PopoverTrigger asChild>
          <div className="hover:bg-muted-foreground flex cursor-pointer flex-row items-center space-x-4 rounded p-2">
            <button
              style={{ background: `hsl(var(${props.colorCssVariable}));` }}
              onClick={toggleOpen}
              className="h-8 w-8 rounded-full"
            />
            <p>{props.colorCssVariable}</p>
          </div>
        </PopoverTrigger>
        <PopoverContent className="">
          <ChromePicker defaultView="hsl" color={color} onChange={onChangeColor} />
        </PopoverContent>
      </Popover>
    </div>
  );
};
