import { datadogRum } from '@datadog/browser-rum';
import { assertDefined } from '@inderes/videosync-utils';
import ReactDOM from 'react-dom/client';
import { decodeJwt } from 'jose';
import Cookies from 'js-cookie';

import { App } from './App';
import { JWTDataType } from '../../../apps/videosync-backstage/src/pages/EventAdmin/types';

import '@inderes/videosync-ui-components/dist/tailwind.css';
import './index.css';

const datadogRumApplicationId = import.meta.env.BACKSTAGE_DATADOG_RUM_APPLICATION_ID;
const datadogRumClientToken = import.meta.env.BACKSTAGE_DATADOG_RUM_CLIENT_TOKEN;
const appEnv = assertDefined(import.meta.env.BACKSTAGE_APP_ENV, 'BACKSTAGE_APP_ENV is not defined');

// If configured in the environment, inject Datadog Real User Monitoring
if (datadogRumApplicationId) {
  datadogRum.init({
    applicationId: datadogRumApplicationId,
    clientToken: datadogRumClientToken,
    site: 'datadoghq.eu',
    service: 'videosync-backstage', // dd.service
    env: appEnv, // dd.env
    sessionSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

// Get and decode authorizationToken to get userId, sub in this case, to be logged
const authorizationToken = Cookies.get('jwt');
if (authorizationToken) {
  const token = decodeJwt(authorizationToken) as JWTDataType;
  if (token.user?.sub) {
    // Include user.sub (=userId) from token as part of all DataDog records' metadata
    datadogRum.setGlobalContext({
      userId: token.user.sub,
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
