import { useMemo } from 'react';
import clsx from 'clsx';

export type ColSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type RealGridColumnProps = React.HTMLAttributes<HTMLDivElement> & {
  xs?: ColSize;
  sm?: ColSize;
  md?: ColSize;
  lg?: ColSize;
  xl?: ColSize;
};

export const RealGridColumn = ({ xs, sm, md, lg, xl, ...props }: RealGridColumnProps) => {
  const styles = useMemo(() => {
    const xsValue = xs ?? 12;
    const smValue = sm ?? xsValue;
    const mdValue = md ?? smValue;
    const lgValue = lg ?? mdValue;
    const xlValue = xl ?? lgValue;

    // This is for tailwind jit, irui-hidden is for colsize 0
    const classOps = [
      'irui-hidden',
      'irui-col-span-1',
      'irui-col-span-2',
      'irui-col-span-3',
      'irui-col-span-4',
      'irui-col-span-5',
      'irui-col-span-6',
      'irui-col-span-7',
      'irui-col-span-8',
      'irui-col-span-9',
      'irui-col-span-10',
      'irui-col-span-11',
      'irui-col-span-12',
      'sm:!irui-hidden',
      'sm:!irui-col-span-1',
      'sm:!irui-col-span-2',
      'sm:!irui-col-span-3',
      'sm:!irui-col-span-4',
      'sm:!irui-col-span-5',
      'sm:!irui-col-span-6',
      'sm:!irui-col-span-7',
      'sm:!irui-col-span-8',
      'sm:!irui-col-span-9',
      'sm:!irui-col-span-10',
      'sm:!irui-col-span-11',
      'sm:!irui-col-span-12',
      'md:!irui-hidden',
      'md:!irui-col-span-1',
      'md:!irui-col-span-2',
      'md:!irui-col-span-3',
      'md:!irui-col-span-4',
      'md:!irui-col-span-5',
      'md:!irui-col-span-6',
      'md:!irui-col-span-7',
      'md:!irui-col-span-8',
      'md:!irui-col-span-9',
      'md:!irui-col-span-10',
      'md:!irui-col-span-11',
      'md:!irui-col-span-12',
      'lg:!irui-hidden',
      'lg:!irui-col-span-1',
      'lg:!irui-col-span-2',
      'lg:!irui-col-span-3',
      'lg:!irui-col-span-4',
      'lg:!irui-col-span-5',
      'lg:!irui-col-span-6',
      'lg:!irui-col-span-7',
      'lg:!irui-col-span-8',
      'lg:!irui-col-span-9',
      'lg:!irui-col-span-10',
      'lg:!irui-col-span-11',
      'lg:!irui-col-span-12',
      'xl:!irui-hidden',
      'xl:!irui-col-span-1',
      'xl:!irui-col-span-2',
      'xl:!irui-col-span-3',
      'xl:!irui-col-span-4',
      'xl:!irui-col-span-5',
      'xl:!irui-col-span-6',
      'xl:!irui-col-span-7',
      'xl:!irui-col-span-8',
      'xl:!irui-col-span-9',
      'xl:!irui-col-span-10',
      'xl:!irui-col-span-11',
      'xl:!irui-col-span-12',
    ];

    const makeClass = (val: ColSize, size: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
      return classOps.find((o) => {
        if (size === 'xs') {
          if (val === 0) {
            return o === 'irui-hidden';
          } else {
            return o.includes('col-span-' + val.toString());
          }
        }
        if (val === 0) {
          return o.includes(size) && o.includes('irui-hidden');
        }

        return o.includes(size) && o.includes('col-span-' + val.toString());
      });
    };
    const xsClass = makeClass(xsValue, 'xs');
    const smClass = makeClass(smValue, 'sm');
    const mdClass = makeClass(mdValue, 'md');
    const lgClass = makeClass(lgValue, 'lg');
    const xlClass = makeClass(xlValue, 'xl');
    return clsx(xsClass, smClass, mdClass, lgClass, xlClass);
  }, [xs, sm, md, lg, xl]);

  return <div {...props} className={clsx(styles, props.className)} />;
};
