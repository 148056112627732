import clsx from 'clsx';

export type KebabMenuItemProps = React.HTMLAttributes<HTMLDivElement>;

export const KebabMenuItem = ({ ...props }) => {
  return (
    <div
      {...props}
      className={clsx(
        'irui-font-sans-serif first:irui-pt-4 irui-px-4 irui-pb-4 irui-flex irui-gap-x-2 irui-items-center',
        // eslint-disable-next-line react/prop-types
        props.className,
      )}
    />
  );
};
