const oldVideosyncUrl: string = import.meta.env.BACKSTAGE_OLD_VIDEOSYNC_URL;
const adminUrl = oldVideosyncUrl + '/admin#/main/all';

export const NotFound = () => {
  // middle of the screen
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="text-4xl font-bold">404</h1>
      <p className="text-xl">Page not found</p>

      <a href={adminUrl} className="mt-4 text-blue-500 hover:underline">
        Back to start
      </a>
    </div>
  );
};
