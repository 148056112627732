import clsx from 'clsx';
import { createElement, useState } from 'react';
import { HiChevronUp, HiOutlineChevronDown } from 'react-icons/hi';
import { animated } from 'react-spring';

import { Button } from '../Button/Button';
import { Grid } from '../Grid';
import { Title } from '../Title';
import { useHeightAnimation } from '../hooks/useHeightAnimation';
import { SubAccordionBaseProps } from './types';

type SubAccordionProps = React.HTMLAttributes<HTMLDivElement> & SubAccordionBaseProps;

export const SubAccordion = ({
  defaultOpen = false,
  accordionTitle,
  icon,
  iconProps,
  buttonProps,
  children,
  ...props
}: SubAccordionProps) => {
  const [accordionOpen, setAccordionOpen] = useState(defaultOpen);

  const { expand, contentRef, shouldOverflow } = useHeightAnimation(accordionOpen, {
    duration: 200,
  });

  const iconElem =
    icon &&
    createElement(icon, {
      ...iconProps,
      className: iconProps?.className,
    });

  return (
    <div {...props} className={clsx('irui-flex irui-flex-col irui-w-full', props.className)}>
      <Grid className="irui-items-center irui-mb-8">
        <Button
          {...buttonProps}
          variant="tertiary"
          className={clsx('irui-flex irui-items-center irui-font-semibold', buttonProps?.className)}
          onClick={() => setAccordionOpen(!accordionOpen)}
        >
          {!!iconElem && iconElem}
          <Title variant="h6" className="irui-mr-4">
            {accordionTitle}
          </Title>
          {accordionOpen ? <HiChevronUp size={20} /> : <HiOutlineChevronDown size={20} />}
        </Button>
      </Grid>
      <animated.div style={{ ...expand }} className={clsx(!shouldOverflow && 'irui-overflow-hidden')}>
        <div className="irui-flex irui-flex-wrap" ref={contentRef}>
          {children}
        </div>
      </animated.div>
    </div>
  );
};
