import { useContext, createContext } from 'react';
import { FirstDayOfWeek, MonthType } from '@datepicker-react/hooks';

type DatepickerContextValue = {
  firstDayOfWeek: FirstDayOfWeek;
  activeMonths: MonthType[];
  isDateSelected: (date: Date) => boolean;
  isDateHovered: (date: Date) => boolean;
  isFirstOrLastSelectedDate: (date: Date) => boolean;
  isDateBlocked: (date: Date) => boolean;
  isDateFocused: (date: Date) => boolean;
  focusedDate: Date | null;
  onDateHover: (date: Date | null) => void;
  onDateSelect: (date: Date) => void;
  onDateFocus: (date: Date) => void;
  goToPreviousMonths: () => void;
  goToNextMonths: () => void;
};

export const DatepickerContext = createContext<DatepickerContextValue | null>(null);

export const useDatepickerContext = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const c = useContext(DatepickerContext)!;
  return c;
};
