import clsx from 'clsx';

export type TableCellProps = React.DetailedHTMLProps<
  React.TdHTMLAttributes<HTMLTableDataCellElement>,
  HTMLTableDataCellElement
>;

export const TableCell = ({ ...props }: TableCellProps) => {
  return (
    <td
      {...props}
      className={clsx(
        'irui-p-2 irui-border irui-border-solid irui-border-newgray-400 irui-font-sans-serif irui-text-sm',
        props.className,
      )}
    />
  );
};
