import clsx from 'clsx';

type RealGridRowProps = React.HTMLAttributes<HTMLDivElement> & { innerRef?: React.MutableRefObject<any> };

export const RealGridRow = ({ innerRef, ...props }: RealGridRowProps) => {
  return (
    <div
      {...props}
      ref={innerRef}
      className={clsx('irui-grid irui-grid-cols-12 irui-col-span-12 irui-w-full irui-gap-4', props.className)}
    />
  );
};
