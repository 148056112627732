export const allAvailableTeleconferenceNumbers = [
  {
    location: 'Australia',
    number: '+61 3 8652 1907',
  },
  {
    location: 'Austria',
    number: '+43 720 883 197',
  },
  {
    location: 'Belgium',
    number: '+32 2 588 01 01',
  },
  {
    location: 'Bulgaria',
    number: '+359 2 437 2475',
  },
  {
    location: 'China',
    number: '+86 10 8524 1350',
  },
  {
    location: 'Czech Republic',
    number: '+420 255 719 144',
  },
  {
    location: 'Denmark',
    number: '+45 89 88 10 84',
  },
  {
    location: 'Estonia',
    number: '+372 880 3680',
  },
  {
    location: 'Finland',
    number: '+358 9 4245 0972',
  },
  {
    location: 'France',
    number: '+33 1 7639 0000',
  },
  {
    location: 'Germany',
    number: '+49 69 9753 3137',
  },
  {
    location: 'Ireland',
    number: '+353 1 531 4561',
  },
  {
    location: 'Italy',
    number: '+39 06 6220 7181',
  },
  {
    location: 'Japan',
    number: '+81 3 4579 9748',
  },
  {
    location: 'Malaysia',
    number: '+60 3 9212 1198',
  },
  {
    location: 'Netherlands',
    number: '+31 20 890 8060',
  },
  {
    location: 'Norway',
    number: '+47 21 9994 00',
  },
  {
    location: 'Singapore',
    number: '+65 3158 3519',
  },
  {
    location: 'Spain',
    number: '+34 931 76 10 03',
  },
  {
    location: 'Sweden',
    number: '+46 8 525 07003',
  },
  {
    location: 'Switzerland',
    number: '+41 31 528 0400',
  },
  {
    location: 'United Kingdom',
    number: '+44 20 7043 5048',
  },
  {
    location: 'United States',
    number: '+1 (774) 450-9900',
  },
];

