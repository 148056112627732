import { useRef } from 'react';
import { useDay } from '@datepicker-react/hooks';
import clsx from 'clsx';

import { useDatepickerContext } from './DatepickerContext';

export type DayProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  date: Date;
  day: string;
};

export const Day = ({ date, day, ...props }: DayProps) => {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useDatepickerContext();

  const { isSelected, isSelectedStartOrEnd, onClick, onKeyDown, onMouseEnter, tabIndex } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });
  if (!day) {
    return <div />;
  }
  return (
    <button
      {...props}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      className={clsx(
        isSelected || isSelectedStartOrEnd ? 'irui-text-newgreen-200' : 'irui-text-black',
        isSelected || isSelectedStartOrEnd ? 'irui-bg-newgreen-200' : 'irui-bg-white',
        'irui-rounded-full',
        props.className,
      )}
      style={{}}
    >
      {day}
    </button>
  );
};
