import clsx from 'clsx';
import { useCallback, useMemo, useRef, useState } from 'react';
import { IoCopyOutline } from 'react-icons/io5';

import { Label, LabelProps } from '../Label';

export type TextInputProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  fullwidth?: boolean;
  error?: boolean;
  label?: string;
  value?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputId?: string;
  disabled?: boolean;
  placeholder?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  labelProps?: LabelProps;
  sideItem?: React.ReactNode;
  sideItemPosition?: 'left' | 'right';
  removeBorder?: boolean;
  readOnly?: boolean;
  readOnlyButtonProps?: React.HTMLAttributes<HTMLDivElement>;
};

export const TextInput = ({
  fullwidth,
  error,
  label,
  inputProps,
  labelProps,
  className,
  value,
  onChange,
  inputId,
  placeholder,
  disabled,
  containerProps,
  sideItem,
  sideItemPosition = 'right',
  removeBorder,
  readOnly,
  readOnlyButtonProps,
  ...props
}: TextInputProps) => {
  const [inFocus, setInFocus] = useState(false);
  const [valueState, setValueState] = useState(inputProps?.value ?? value ?? '');
  const inputRef = useRef<HTMLInputElement>(null);
  const absValue = useMemo(() => {
    if (typeof inputProps?.value === 'string') {
      return inputProps.value;
    }
    if (typeof value === 'string') {
      return value;
    }
    return valueState;
  }, [value, valueState, inputProps?.value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValueState(e.target.value);
      onChange?.(e);
      inputProps?.onChange?.(e);
    },
    [onChange, inputProps],
  );

  const wrapperClasses = useMemo(() => {
    const base = clsx('irui-inline-flex irui-flex-col', {
      'irui-w-full': fullwidth,
      'irui-w-auto': !fullwidth,
    });
    return base;
  }, [fullwidth]);

  const containerClasses = useMemo(() => {
    const base = clsx(
      !removeBorder && 'irui-border-solid irui-border',
      'irui-w-full irui-flex irui-bg-transparent irui-rounded-sm irui-h-[52px]',
    );
    if (disabled) {
      return clsx(base, 'irui-border-newgray-200 irui-cursor-default');
    }
    if (error) {
      if (inFocus) {
        return clsx(base, 'irui-border-red-900 irui-cursor-text hover:irui-border-red-900 focus:irui-border-red-900');
      }
      return clsx(base, 'irui-border-red-700 irui-cursor-text hover:irui-border-red-900 focus:irui-border-red-900');
    }

    if (inFocus) {
      return clsx(
        base,
        !readOnly && 'hover:irui-border-newgray-900 focus:irui-border-newgray-900 irui-border-newgray-900 ',
        'irui-cursor-text',
      );
    }
    return clsx(
      base,
      !readOnly && 'hover:irui-border-newgray-900 focus:irui-border-newgray-900',
      'irui-border-newgray-400 irui-cursor-text',
    );
  }, [error, disabled, inFocus, removeBorder, readOnly]);

  const inputClasses = useMemo(() => {
    const base =
      'irui-leading-[24px] irui-font-sans-serif irui-w-full irui-outline-none focus:irui-outline-none irui-bg-transparent irui-py-3.5 irui-px-5 placeholder-newgray-500';
    if (disabled) {
      return clsx(base, 'irui-text-newgray-400 irui-placeholder-newgray-400');
    }
    if (error) {
      return clsx(base, 'irui-text-red-700 irui-placeholder-red-700 irui-placeholder-opacity-75');
    }
    return clsx(base, 'irui-text-newgray-900 dark:irui-text-white');
  }, [error, disabled]);

  return (
    <div {...props} className={clsx(wrapperClasses, className)}>
      {(labelProps?.children || label) && (
        <Label
          {...labelProps}
          disabled={inputProps?.disabled ?? disabled}
          error={error}
          inputRef={inputRef}
          inputId={inputProps?.id ?? inputId ?? undefined}
        >
          {labelProps?.children ?? label}
        </Label>
      )}
      <div
        {...containerProps}
        className={clsx(containerClasses, containerProps?.className)}
        onClick={(e) => {
          containerProps?.onClick?.(e);
        }}
      >
        {sideItem && sideItemPosition === 'left' && (
          <div className="irui-h-full irui-w-14 irui-rounded-tl irui-rounded-bl">{sideItem}</div>
        )}
        <input
          {...inputProps}
          readOnly={readOnly ?? false}
          type="text"
          value={absValue}
          onChange={handleChange}
          id={inputProps?.id ?? inputId ?? undefined}
          className={clsx(inputClasses, inputProps?.className)}
          ref={inputRef}
          onFocus={(e) => {
            setInFocus(true);
            inputProps?.onFocus?.(e);
          }}
          onBlur={(e) => {
            setInFocus(false);
            inputProps?.onBlur?.(e);
          }}
          placeholder={inputProps?.placeholder ?? placeholder ?? undefined}
          disabled={inputProps?.disabled ?? disabled ?? undefined}
        />
        {readOnly && (
          <button
            className={clsx(
              'irui-h-full irui-w-14 irui-rounded-tr irui-rounded-br irui-inline-flex irui-items-center irui-justify-center focus:irui-outline-none irui-cursor-pointer',
              readOnlyButtonProps?.className,
            )}
            onClick={() => {
              inputRef.current?.select();
              inputRef.current?.setSelectionRange(0, 99999);
              void navigator.clipboard.writeText(inputRef.current?.value ?? '');
            }}
          >
            <IoCopyOutline size={20} />
          </button>
        )}
        {sideItem && sideItemPosition === 'right' && (
          <div className="irui-h-full irui-w-14 irui-rounded-tr irui-rounded-br">{sideItem}</div>
        )}
      </div>
    </div>
  );
};
