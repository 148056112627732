import { useHydrateAtoms } from 'jotai/utils';
import Cookies from 'js-cookie';

/**
 * Function to make sure url has scheme
 */
export const urlWithScheme = (url?: string) => {
  if (!url) return '';
  if (url.startsWith('http://') || url.startsWith('https://')) return url;
  return `${location.protocol}//${url}`;
};

export const checkThatVideosyncTokenExistsAndIsNotExpired = () => {
  const token = Cookies.get('jwt');
  if (!token) {
    throw new Error('Token not found');
  }
  const decoded = JSON.parse(window.atob(token.split('.')[1]));
  if (Date.now() >= decoded.exp * 1000) {
    throw new Error('Token expired');
  }
  return token;
};

interface GenerateEventAdminLinkParams {
  eventId: string;
  accountName: string;
  path?: string;
}

export const generateEventAdminLink = ({
  eventId,
  accountName,
  path = 'basic',
}: GenerateEventAdminLinkParams): string => {
  return `${location.protocol}//${location.host}/event-admin/${accountName}/${eventId}/${path}`;
};

interface HydrateAtomsProps {
  initialValues: any;
  children: any;
}

export const HydrateAtoms = ({ initialValues, children }: HydrateAtomsProps) => {
  useHydrateAtoms(initialValues);
  return children;
};