import { Label, LabelProps } from '../Label';
import { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';

export type RangeSliderProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  labelProps?: LabelProps;
  label?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  disabled?: boolean;
  error?: boolean;
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  fullwidth?: boolean;
  onChange?: (value: number) => void;
};

export const RangeSlider = ({
  labelProps,
  label,
  disabled,
  inputProps,
  error,
  min,
  max,
  step,
  value,
  onChange,
  fullwidth,
  ...props
}: RangeSliderProps) => {
  const [valueState, setValueState] = useState(value ?? 0);

  const valueAbs = useMemo(() => {
    return value ?? valueState;
  }, [valueState, value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = Number(e.target.value);
      setValueState(v);
      onChange?.(v);
      inputProps?.onChange?.(e);
    },
    [inputProps, setValueState, onChange],
  );

  return (
    <div
      {...props}
      className={clsx('irui-flex irui-flex-col', fullwidth ? 'irui-w-full' : 'irui-w-max', props.className)}
    >
      {(labelProps?.children || label) && (
        <Label {...labelProps} disabled={inputProps?.disabled ?? disabled} error={error}>
          {labelProps?.children ?? label}
        </Label>
      )}
      <input
        {...inputProps}
        className={clsx('irui-w-full', inputProps?.className)}
        value={valueAbs}
        onChange={handleChange}
        min={inputProps?.min ?? min ?? 0}
        max={inputProps?.max ?? max ?? 10}
        step={inputProps?.step ?? step ?? 1}
        type="range"
      />
    </div>
  );
};
