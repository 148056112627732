import { useEffect, useRef, useMemo, useState, forwardRef, ForwardedRef } from 'react';
import clsx from 'clsx';
import interact from 'interactjs';

export type ResizableContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  containerTargetClass?: string;
  edges?: any; // ResizableOptions['edges'];
  listeners?: any; // ResizableOptions['listeners'];
  resizableOptions?: any; //ResizableOptions;
  ref?: React.RefObject<HTMLDivElement>;
};

export const ResizableContainer = forwardRef(function ResizableContainer(
  { containerTargetClass, resizableOptions, edges, listeners, ...props }: ResizableContainerProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  // Create unique id for resizable class to avoid multiple containers colliding with eachother
  // Use ref to create value exactly once
  const resizableClassIdRef = useRef<string>();

  if (!resizableClassIdRef.current) {
    resizableClassIdRef.current = Math.random().toString().substr(2, 12);
  }

  const targetClass = useMemo(() => {
    return containerTargetClass ?? '__resizable-' + (resizableClassIdRef.current || '');
  }, [containerTargetClass, resizableClassIdRef]);

  const [styles, setStyles] = useState<React.CSSProperties>({});

  useEffect(() => {
    const t = '.' + targetClass;
    const ops = resizableOptions ?? {
      edges: edges ?? { left: true, right: true, bottom: true, top: true },
      listeners: listeners ?? {
        move: (e: any) => {
          setStyles({
            height: (e.rect.height as number).toString() + 'px',
          });
        },
      },
    };
    interact(t).resizable(ops);
    return () => {
      interact(t).unset();
    };
  }, [targetClass, setStyles, resizableOptions, edges, listeners]);

  return (
    <div {...props} style={{ ...styles, ...props.style }} className={clsx(targetClass, props?.className)} ref={ref} />
  );
});
