import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from '@tanstack/react-router';
import { httpLink } from '@trpc/client';
import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';

import { JotaiProvider } from './jotai-service';
import { TailwindIndicator } from './components/TailwindIndicator';
import { trpc, trpcUrl } from './hooks/trpc';
import { router } from './routes';

// reloads the app if there is an error fetching an outdated chunk due to a new build deployed
window.addEventListener('vite:preloadError', () => {
  console.log('Triggering reload due to preload error');
  window.location.reload();
});

export const App = () => {
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          url: trpcUrl,
          headers() {
            const token = sessionStorage.getItem('webStudioToken');
            return {
              authorization: `Bearer ${Cookies.get('jwt')}`,
              'x-request-id': nanoid(),
              'x-session-id': datadogRum.getInternalContext()?.session_id,

              // this is only used for the web studio
              'x-web-studio-token': `Bearer ${token}`,
            };
          },
        }),
      ],
    }),
  );

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  // clean up on unmount
  useEffect(() => {
    return () => {
      sessionStorage.removeItem('webStudioToken');
    };
  }, []);

  return (
    <JotaiProvider>
      <QueryClientProvider client={queryClient}>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <RouterProvider router={router} />
        </trpc.Provider>

        <TailwindIndicator />

        <ReactQueryDevtools
          initialIsOpen={false}
          position="bottom-right"
          toggleButtonProps={{ style: { bottom: 60 } }}
        />
      </QueryClientProvider>
    </JotaiProvider>
  );
};
