import { FirstDayOfWeek, useMonth, UseMonthProps } from '@datepicker-react/hooks';
import clsx from 'clsx';

import { Day } from './Day';
import { Title } from '../../Title';

export type MonthProps = React.HTMLAttributes<HTMLDivElement> & {
  useMonthProps?: Partial<UseMonthProps>;
  year: number;
  month: number;
  firstDayOfWeek: FirstDayOfWeek;
};

export const Month = ({ year, month, firstDayOfWeek, useMonthProps, ...props }: MonthProps) => {
  const { days, weekdayLabels, monthLabel } = useMonth({
    ...useMonthProps,
    year,
    month,
    firstDayOfWeek,
  });
  const dayss = days as { dayLabel: string; date: Date }[];

  return (
    <div
      {...props}
      className={clsx('irui-flex irui-flex-col irui-items-start irui-w-52 max-w-screen', props.className)}
    >
      <Title variant="h6" className="irui-w-full irui-text-center">
        {monthLabel}
      </Title>
      <div className="irui-grid irui-grid-cols-7 irui-justify-center irui-w-full">
        {weekdayLabels.map((d) => (
          <div className="irui-text-center" key={d}>
            {d}
          </div>
        ))}
      </div>
      <div className="irui-grid irui-grid-cols-7 irui-justify-center irui-w-full">
        {dayss.map((d, index) => (
          <Day date={d.date} key={index} day={d.dayLabel} />
        ))}
      </div>
    </div>
  );
};
