import { appEnv } from '../config';

export const TailwindIndicator = () => {
  if (appEnv === 'production') return null;

  return (
    <div className="fixed bottom-1 right-16 z-50 flex h-6 w-auto items-center justify-center rounded-full bg-gray-800 p-3 text-xs text-white">
      <div className="block sm:hidden">xs</div>
      <div className="hidden sm:block md:hidden">sm</div>
      <div className="hidden md:block lg:hidden">md</div>
      <div className="hidden lg:block xl:hidden">lg</div>
      <div className="hidden xl:block 2xl:hidden">xl</div>
      <div className="hidden 2xl:block">2xl</div>
    </div>
  );
};
