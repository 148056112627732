import { TextInput, TextInputProps } from '../TextInput';
import { useCallback, useMemo, useRef, useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { IconBaseProps } from 'react-icons';
import { animated } from 'react-spring';
import clsx from 'clsx';

import { Label, LabelProps } from '../Label';
import { useHeightAnimation } from '../../hooks/useHeightAnimation';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const timePickerHourOptions = new Array(24).fill(true).map((_, i) => {
  let s = i.toString();
  if (s.length === 1) {
    s = '0' + s;
  }
  return s;
});

const timePickerMinuteOptions = new Array(60).fill(true).map((_, i) => {
  let s = i.toString();
  if (s.length === 1) {
    s = '0' + s;
  }
  return s;
});

export type TimepickerProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  // 00-23
  hourValue?: string;
  // 00-59
  minuteValue?: string;
  onChange?: (hour: string, minute: string) => void;
  inputsWrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  hourInputContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  hourInputProps?: TextInputProps;
  seperatorContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  minuteInputContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  minuteInputProps?: TextInputProps;
  iconProps?: IconBaseProps;
  fullwidth?: boolean;
  label?: string;
  labelProps?: LabelProps;
  error?: boolean;
  disabled?: boolean;
  pickerVisible?: boolean;
  onPickerVisibleToggle?: (to: boolean) => void;
  toggleButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  dropdownOpenSide?: 'right' | 'left';
};

export const Timepicker = ({
  hourValue,
  minuteValue,
  onChange,
  inputsWrapperProps,
  hourInputContainerProps,
  hourInputProps,
  seperatorContainerProps,
  minuteInputContainerProps,
  minuteInputProps,
  iconProps,
  fullwidth,
  label,
  labelProps,
  error,
  disabled,
  pickerVisible,
  onPickerVisibleToggle,
  toggleButtonProps,
  dropdownOpenSide = 'right',
  ...props
}: TimepickerProps) => {
  const [hourValueState, setHourValueState] = useState(hourValue ?? '');
  const [minuteValueState, setMinuteValueState] = useState(minuteValue ?? '');

  const hourValueAbs = useMemo(() => {
    return hourValue ?? hourValueState;
  }, [hourValue, hourValueState]);

  const minuteValueAbs = useMemo(() => {
    return minuteValue ?? minuteValueState;
  }, [minuteValue, minuteValueState]);

  const setHour = useCallback(
    (value: string) => {
      const validValues = [
        ...new Array(24).fill(true).map((_, index) => index.toString()),
        ...new Array(10).fill(true).map((_, index) => '0' + index.toString()),
        '',
      ];

      if (validValues.includes(value)) {
        setHourValueState(value);
        onChange?.(value, minuteValueAbs);
      }
    },
    [setHourValueState, onChange, minuteValueAbs],
  );

  const setMinute = useCallback(
    (value: string) => {
      const validValues = [
        ...new Array(60).fill(true).map((_, index) => index.toString()),
        ...new Array(10).fill(true).map((_, index) => '0' + index.toString()),
        '',
      ];

      if (validValues.includes(value)) {
        setMinuteValueState(value);
        onChange?.(hourValueAbs, value);
      }
    },
    [setMinuteValueState, onChange, hourValueAbs],
  );

  const handleHourChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setHour(value);

      hourInputProps?.onChange?.(e);
    },
    [hourInputProps, setHour],
  );

  const handleMinuteChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setMinute(value);
      minuteInputProps?.onChange?.(e);
    },
    [minuteInputProps, setMinute],
  );

  const handleTextInputBlur = useCallback(
    (target: 'minute' | 'hour') => (e: React.FocusEvent<HTMLDivElement>) => {
      if (target === 'hour') {
        hourInputProps?.onBlur?.(e);
        const value = Number(hourValueAbs) < 10 && hourValueAbs.length === 1 ? '0' + hourValueAbs : hourValueAbs;
        setHour(value);
      }

      if (target === 'minute') {
        minuteInputProps?.onBlur?.(e);
        const value =
          Number(minuteValueAbs) < 10 && minuteValueAbs.length === 1 ? '0' + minuteValueAbs : minuteValueAbs;
        setMinute(value);
      }
    },
    [hourInputProps, hourValueAbs, minuteInputProps, minuteValueAbs, setHour, setMinute],
  );

  const [pickerVisibleState, setPickerVisibleState] = useState(false);

  const handleTextInputFocus = useCallback(() => {
    setPickerVisibleState(true);
    onPickerVisibleToggle?.(true);
  }, [setPickerVisibleState, onPickerVisibleToggle]);

  const pickerVisibleAbs = useMemo(() => {
    return pickerVisible ?? pickerVisibleState;
  }, [pickerVisible, pickerVisibleState]);

  const handleToggleClick = useCallback(
    (e: any) => {
      setPickerVisibleState(!pickerVisibleAbs);
      onPickerVisibleToggle?.(!pickerVisibleAbs);
      toggleButtonProps?.onClick?.(e);
    },
    [setPickerVisibleState, onPickerVisibleToggle, toggleButtonProps, pickerVisibleAbs],
  );

  const wrapperRef = useRef(null);

  const onClickOutside = useCallback(() => {
    setPickerVisibleState(false);
    onPickerVisibleToggle?.(false);
  }, [setPickerVisibleState, onPickerVisibleToggle]);

  useOnClickOutside(wrapperRef, onClickOutside);

  const { expand, contentRef } = useHeightAnimation(pickerVisibleAbs, {
    duration: 200,
  });

  return (
    <div {...props} className={clsx('irui-inline-flex irui-flex-col', fullwidth && 'irui-w-full', props.className)}>
      {(labelProps?.children || label) && (
        <Label {...labelProps} disabled={disabled} error={error}>
          {labelProps?.children ?? label}
        </Label>
      )}
      <div
        {...inputsWrapperProps}
        className={clsx('irui-flex irui-w-full irui-h-[52px]', inputsWrapperProps?.className)}
      >
        <div
          {...hourInputContainerProps}
          className={clsx(fullwidth ? 'irui-flex-1 irui-w-full' : 'irui-w-16', hourInputContainerProps?.className)}
        >
          <TextInput
            {...hourInputProps}
            inputProps={{
              ...hourInputProps?.inputProps,
              className: clsx('irui-text-center', hourInputProps?.className),
              onBlur: handleTextInputBlur('hour'),
              onFocus: handleTextInputFocus,
            }}
            fullwidth
            onChange={handleHourChange}
            value={hourValueAbs}
          />
        </div>
        <div
          {...seperatorContainerProps}
          className={clsx(
            'irui-px-2 irui-text-lg irui-inline-flex irui-items-center irui-h-full',
            seperatorContainerProps?.className,
          )}
        >
          :
        </div>
        <div
          {...minuteInputContainerProps}
          className={clsx(fullwidth ? 'irui-flex-1 irui-w-full' : 'irui-w-16', minuteInputContainerProps?.className)}
        >
          <TextInput
            {...minuteInputProps}
            inputProps={{
              ...minuteInputProps?.inputProps,
              className: clsx('irui-text-center', minuteInputProps?.className),
              onBlur: handleTextInputBlur('minute'),
              onFocus: handleTextInputFocus,
            }}
            fullwidth
            onChange={handleMinuteChange}
            value={minuteValueAbs}
          />
        </div>
        <div
          ref={wrapperRef}
          className={clsx(
            'irui-h-full irui-w-[52px] irui-ml-2 irui-flex-shrink-0 irui-rounded-sm',
            'irui-flex irui-justify-center irui-items-center irui-p-3 irui-bg-newgreen-200 irui-relative',
          )}
        >
          <button
            {...toggleButtonProps}
            onClick={handleToggleClick}
            className={clsx('irui-w-full irui-h-full', toggleButtonProps?.className)}
          >
            <AiOutlineClockCircle
              {...iconProps}
              className={clsx('irui-w-full irui-h-full irui-text-white', iconProps?.className)}
            />
          </button>
          <animated.div
            style={{ ...expand }}
            className={clsx(
              dropdownOpenSide === 'right' ? 'irui-left-0' : 'irui-right-0',
              'irui-absolute irui-top-[68px] irui-overflow-hidden irui-min-w-[248px] irui-shadow-lg irui-rounded irui-z-50',
            )}
          >
            <div className="irui-flex irui-w-full irui-bg-white irui-justify-between irui-max-h-48" ref={contentRef}>
              <div className="irui-inline-flex irui-min-h-0 irui-max-h-48 irui-overflow-y-auto irui-w-1/2">
                <div className="irui-inline-flex irui-flex-col irui-divide-y irui-divide-gray-50 irui-w-full">
                  {timePickerHourOptions.map((h) => {
                    return (
                      <button
                        onClick={() => setHour(h)}
                        key={h}
                        className={clsx(
                          'irui-py-4 irui-w-full',
                          (hourValueAbs === h || hourValueAbs.split('')[1] === h) && 'irui-bg-gray-50',
                        )}
                      >
                        {h}
                      </button>
                    );
                  })}
                </div>
              </div>
              <div className="irui-inline-flex irui-min-h-0 irui-max-h-48 irui-overflow-y-auto irui-w-1/2">
                <div className="irui-inline-flex irui-flex-col irui-divide-y irui-divide-gray-50 irui-w-full">
                  {timePickerMinuteOptions.map((m) => (
                    <button
                      onClick={() => setMinute(m)}
                      key={m}
                      className={clsx(
                        'irui-py-4 irui-w-full',
                        (minuteValueAbs === m || minuteValueAbs.split('')[1] === m) && 'irui-bg-gray-50',
                      )}
                    >
                      {m}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </animated.div>
        </div>
      </div>
    </div>
  );
};
