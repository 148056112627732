import clsx from 'clsx';
import { createElement, useCallback, useMemo, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { HiCheckCircle } from 'react-icons/hi';
import { MdRadioButtonUnchecked } from 'react-icons/md';

export type CheckboxWithContentProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onChange'> & {
  contentContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  iconProps?: IconBaseProps;
  checked?: boolean;
  onChange?: (to: boolean) => void;
  fullwidth?: boolean;
};

export const CheckboxWithContent = ({
  checked,
  onChange,
  contentContainerProps,
  children,
  iconProps,
  fullwidth,
  disabled,
  ...props
}: CheckboxWithContentProps) => {
  const [checkedState, setCheckedState] = useState(checked);

  const checkedAbs = useMemo(() => {
    return checked ?? checkedState;
  }, [checked, checkedState]);

  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setCheckedState(!checkedAbs);
      onChange?.(!checkedAbs);
      props?.onClick?.(e);
    },
    [props, onChange, setCheckedState, checkedAbs],
  );

  return (
    <button
      {...props}
      disabled={disabled}
      className={clsx(
        disabled ? 'irui-cursor-default irui-border-newgray-100' : 'irui-cursor-pointer irui-border-newgray-400',

        'irui-p-0 focus:irui-outline-none irui-outline-none irui-rounded irui-border irui-border-solid',
        fullwidth && 'irui-w-full',
        props.className,
      )}
      onClick={handleChange}
    >
      <div
        {...contentContainerProps}
        className={clsx(
          'irui-p-2 irui-w-full irui-h-full',
          disabled && 'irui-opacity-40',
          contentContainerProps?.className,
        )}
      >
        {createElement(checkedAbs ? HiCheckCircle : MdRadioButtonUnchecked, {
          ...iconProps,
          size: iconProps?.size ?? 20,
          className: clsx(
            !checkedAbs && 'irui-text-newgray-400',
            checkedAbs && 'irui-text-newblue-400',
            iconProps?.className,
          ),
        })}

        {children}
      </div>
    </button>
  );
};
