'use client';

import clsx from 'clsx';
import { HTMLAttributes, ReactNode, useState } from 'react';

interface TooltipProps extends HTMLAttributes<HTMLDivElement> {
  text?: ReactNode;
  position?: 'left' | 'right' | 'top' | 'bottom';
  children: ReactNode;
  className?: string;
  textClassName?: string;
  arrowClassName?: string;
}

export const LegacyTooltip = ({
  text,
  children,
  position = 'top',
  className = '',
  textClassName = '',
  arrowClassName = '',
}: TooltipProps) => {
  const [hovering, setHovering] = useState<boolean>(false);
  return (
    <div
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
      className={clsx(
        'relative flex flex-col',
        className,
        position === 'top' && 'flex-col',
        position === 'bottom' && 'flex-col-reverse',
      )}
    >
      <div
        className={clsx(
          'absolute z-40 flex w-max',
          hovering ? 'visible' : 'invisible',
          position === 'top' && 'bottom-full flex-col',
          position === 'bottom' && 'top-full mt-2 flex-col-reverse',
        )}
      >
        <div className={clsx('p-4 text-sm', textClassName)}>{text}</div>
        <div className={clsx('flex w-full items-center justify-center')}>
          <div
            className={clsx(
              'mb-1',
              position === 'top' && 'border-x-8 border-t-8 border-x-transparent border-t-white',
              position === 'bottom' && 'border-x-8 border-b-8 border-x-transparent border-b-white',
              arrowClassName,
            )}
          />
        </div>
      </div>
      {children}
    </div>
  );
};
