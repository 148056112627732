'use client';

import { forwardRef } from 'react';
import { GripVertical } from 'lucide-react';
import * as ResizablePrimitive from 'react-resizable-panels';

import { cn } from './utils';

// Added this type to shadcn example as it was used in resizable docs
export type ResizablePanelGroupType = ResizablePrimitive.ImperativePanelGroupHandle;

// Added forwardRef to shadcn example to allow for custom ref handling
const ResizablePanelGroup = forwardRef(
  (
    { className, ...props }: React.ComponentProps<typeof ResizablePrimitive.PanelGroup>,
    ref: React.Ref<ResizablePanelGroupType>,
  ) => (
    <ResizablePrimitive.PanelGroup
      ref={ref}
      className={cn('flex h-full w-full data-[panel-group-direction=vertical]:flex-col', className)}
      {...props}
    />
  ),
);

ResizablePanelGroup.displayName = 'ResizablePanelGroup';

const ResizablePanel = ResizablePrimitive.Panel;

interface ResizableHandleProps extends React.ComponentPropsWithoutRef<typeof ResizablePrimitive.PanelResizeHandle> {
  handleClassName?: string;
  handleIconClassName?: string;
}

const ResizableHandle = ({
  withHandle,
  className,
  handleClassName,
  handleIconClassName,
  ...props
}: ResizableHandleProps & {
  withHandle?: boolean;
}) => (
  <ResizablePrimitive.PanelResizeHandle
    className={cn(
      'bg-border focus-visible:ring-ring relative flex w-px items-center justify-center after:absolute after:inset-y-0 after:left-1/2 after:w-1 after:-translate-x-1/2 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-1 data-[panel-group-direction=vertical]:h-px data-[panel-group-direction=vertical]:w-full data-[panel-group-direction=vertical]:after:left-0 data-[panel-group-direction=vertical]:after:h-1 data-[panel-group-direction=vertical]:after:w-full data-[panel-group-direction=vertical]:after:-translate-y-1/2 data-[panel-group-direction=vertical]:after:translate-x-0 [&[data-panel-group-direction=vertical]>div]:rotate-90',
      className,
    )}
    {...props}
  >
    {withHandle && (
      <div className={cn('bg-border z-10 flex h-4 w-3 items-center justify-center rounded-sm border', handleClassName)}>
        <GripVertical className={cn('h-2.5 w-2.5', handleIconClassName)} />
      </div>
    )}
  </ResizablePrimitive.PanelResizeHandle>
);

export { ResizablePanelGroup, ResizablePanel, ResizableHandle };
