import { LoaderSpinner } from '../LoaderSpinner';
import { useCallback, useMemo, useState } from 'react';
import {
  DropEvent,
  DropzoneInputProps,
  DropzoneOptions,
  DropzoneRootProps,
  FileRejection,
  useDropzone,
} from 'react-dropzone';
import clsx from 'clsx';

export type FileUploaderProps = Omit<DropzoneRootProps, 'onDrop'> & {
  options?: Partial<Omit<DropzoneOptions, 'onDrop'>>;
  onDrop?: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => Promise<void>;
  inputProps?: DropzoneInputProps;
};

export const FileUploader = ({ options, children, onDrop: onDropProp, inputProps, ...props }: FileUploaderProps) => {
  const [loading, setLoading] = useState(false);
  const onDrop = useCallback(
    async (files: File[], rejected: FileRejection[], event: DropEvent) => {
      // const f = files[0];
      setLoading(true);

      await onDropProp?.(files, rejected, event);

      setLoading(false);
    },
    [onDropProp],
  );

  const dropzoneOptions = useMemo<DropzoneOptions>(() => {
    return {
      ...options,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onDrop,
    };
  }, [options, onDrop]);

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  return (
    <div
      {...getRootProps({
        ...props,
        className: clsx(
          'irui-w-full irui-h-full irui-border irui-border-dashed irui-border-newgray-600 irui-rounded irui-cursor-pointer',
          props?.className,
        ),
      })}
    >
      <input
        {...getInputProps({
          ...inputProps,
        })}
      />
      <div className="irui-flex irui-flex-col irui-items-center irui-justify-center irui-w-full irui-h-full irui-bg-transparent">
        {loading ? <LoaderSpinner size="xl" /> : children}
      </div>
    </div>
  );
};
