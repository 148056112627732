export const RootContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className="flex min-h-screen flex-col"
      style={{ background: 'linear-gradient(148.5deg, rgba(0, 14, 36, 0.88) 0.42%, rgba(4, 1, 9, 0.97) 110.84%)' }}
    >
      <div className="flex-grow">{children}</div>
    </div>
  );
};
