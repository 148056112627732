import clsx from 'clsx';
import { useMemo } from 'react';
import { ButtonBaseProps } from './button-types';

export function useButtonClasses({ large, variant, disabled, fullwidth }: ButtonBaseProps & { disabled?: boolean }) {
  const primary = 'irui-bg-newgreen-400 irui-text-white dark:irui-text-newgray-800 irui-border irui-border-transparent';
  const secondary = 'irui-bg-newblue-100 irui-text-newgreen-500 irui-border irui-border-solid irui-border-newgreen-200';
  const tertiary =
    'irui-bg-transparent dark:irui-text-white dark:hover:irui-text-newgray-500 dark:focus:irui-text-newgray-500';
  const outlined = 'irui-border irui-border-solid';

  const variantClasses = useMemo(() => {
    return {
      primary: clsx(primary, 'hover:irui-bg-newgreen-500 focus:irui-bg-newgreen-500'),
      secondary: clsx(secondary, 'hover:irui-bg-newblue-200 focus:irui-bg-newblue-200'),
      tertiary: clsx(tertiary, 'hover:irui-text-newgray-800 focus:irui-text-newgray-800 irui-text-newgray-800'),
      outlined: clsx(
        tertiary,
        outlined,
        'hover:irui-border-newgray-800 focus:irui-border-newgray-800 irui-text-newgray-800 irui-border-newgray-400',
      ),
    };
  }, [primary, secondary, tertiary, outlined]);

  const disabledClasses = useMemo(() => {
    return {
      primary: clsx(primary, 'irui-bg-newgreen-200'),
      secondary: clsx(secondary, 'irui-opacity-50'),
      tertiary: clsx(tertiary, 'irui-text-newgray-400'),
      outlined: clsx(tertiary, outlined, 'irui-text-newgray-400 irui-border-newgray-400'),
    };
  }, [primary, secondary, tertiary, outlined]);

  return clsx(
    disabled
      ? [disabledClasses[variant ?? 'primary'], 'irui-cursor-default']
      : [variantClasses[variant ?? 'primary'], 'irui-cursor-pointer'],
    fullwidth && 'irui-w-full',
    large ? 'irui-py-4 irui-px-5' : 'irui-py-2 irui-px-3.5',
    'irui-font-sans-serif irui-text-sm irui-outline-none focus:irui-outline-none irui-transition irui-duration-500',
    'irui-whitespace-nowrap irui-rounded-sm',
  );
}
