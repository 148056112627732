import clsx from 'clsx';
import { Label, LabelProps } from '../Label';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type TabSelectOption = {
  label: string;
  value: string;
};

export type TabSelectProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  options?: TabSelectOption[];
  value?: TabSelectOption;
  onChange?: (option: TabSelectOption) => void;
  label?: string;
  labelProps?: LabelProps;
  error?: boolean;
  disabled?: boolean;
  selectContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  selectedBackgroundElementProps?: React.HTMLAttributes<HTMLDivElement>;
  buttonProps?: React.HTMLAttributes<HTMLButtonElement>;
};

export const TabSelect = ({
  options = [],
  value,
  onChange,
  label,
  labelProps,
  error,
  disabled,
  selectContainerProps,
  selectedBackgroundElementProps,
  buttonProps,
  ...props
}: TabSelectProps) => {
  const [valueState, setValueState] = useState(value ?? options[0]);
  const [width, setWidth] = useState(0);

  const selectedRef = useRef<HTMLButtonElement>(null);

  const valueAbs = useMemo(() => {
    return value ?? valueState;
  }, [value, valueState]);

  const handleChange = useCallback(
    (option: TabSelectOption) => (e: React.MouseEvent<HTMLButtonElement>) => {
      setValueState(option);
      onChange?.(option);
    },
    [setValueState, onChange],
  );

  const [backgroundElemRect, setBackgroundElemRect] = useState<{
    left: number;
    width: number;
  }>({
    left: 0,
    width: 0,
  });

  useEffect(() => {
    const selectedNode = selectedRef.current;

    // Observe element width changes (e.g. window or parent element resizes)
    if (selectedRef?.current) {
      const observer = new ResizeObserver((entries) => {
        setWidth(entries[0].contentRect.width);
      });
      observer.observe(selectedRef.current);
      return () => {
        if (selectedNode) observer.unobserve(selectedNode);
      };
    }
  }, []);

  useEffect(() => {
    // React to value, width or ref changes
    if (selectedRef.current) {
      const rect = selectedRef.current.getBoundingClientRect();
      const newValues = { left: selectedRef.current.offsetLeft, width: rect.width };
      if (backgroundElemRect?.left !== newValues?.left || backgroundElemRect?.width !== newValues?.width) {
        setBackgroundElemRect(newValues);
      }
    }
  }, [valueAbs, width, selectedRef, backgroundElemRect?.left, backgroundElemRect?.width]);

  return (
    <div {...props} className={clsx('irui-inline-flex irui-flex-col', props.className)}>
      {(labelProps?.children || label) && (
        <Label {...labelProps} className="irui-mb-4" disabled={disabled} error={error}>
          {labelProps?.children ?? label}
        </Label>
      )}
      <div
        {...selectContainerProps}
        className={clsx(
          'irui-relative irui-rounded-[18px] irui-w-max irui-border irui-border-solid irui-border-newgray-400 irui-bg-newgray-100 irui-inline-flex irui-items-center',
          selectContainerProps?.className,
        )}
      >
        {options.map((o) => (
          <button
            key={o.value}
            ref={valueAbs?.value === o?.value ? selectedRef : null}
            onClick={handleChange(o)}
            className={clsx(
              'irui-transition-all irui-duration-500 irui-rounded-[52px] irui-z-10 irui-bg-transparent irui-h-full irui-px-3.5 irui-py-2 irui-mx-[2.5px] irui-my-[3.5px] focus:irui-outline-none irui-text-xs !irui-leading-3 irui-text-newgray-800 irui-font-sans-serif',
              valueAbs?.value === o?.value && '!irui-text-white',
              buttonProps?.className,
            )}
          >
            {o.label}
          </button>
        ))}
        <div
          style={{
            left: `${backgroundElemRect.left.toString()}px`,
            width: backgroundElemRect.width,
          }}
          className={clsx(
            'irui-absolute irui-top-[2px] irui-bottom-[2px] irui-rounded-[52px] irui-z-0 irui-bg-newblue-400 transtion-all duration 500',
            selectedBackgroundElementProps?.className,
          )}
        />
      </div>
    </div>
  );
};
