import { TextareaHTMLAttributes, forwardRef } from 'react';
import { cn } from './utils';

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ children, className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        'border-border bg-background ring-offset-background placeholder:text-muted-alt focus-visible:ring-primary text-foreground flex min-h-[60px] w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      ref={ref}
      {...props}
    >
      {children}
    </textarea>
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
