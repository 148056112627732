import clsx from 'clsx';
import { useMemo } from 'react';

export type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  inputId?: string;
  inputRef?: React.RefObject<HTMLInputElement> | React.RefObject<HTMLTextAreaElement>;
  error?: boolean;
  disabled?: boolean;
  removeMargin?: boolean;
  uppercased?: boolean;
};

export const Label = ({ disabled, error, inputId, inputRef, removeMargin, uppercased, ...props }: LabelProps) => {
  const labelClasses = useMemo(() => {
    const base = 'irui-font-sans-serif irui-text-xs irui-bg-transparent irui-tracking-wider';
    if (disabled) {
      return clsx(base, 'irui-text-newgray-400');
    }
    if (error) {
      return clsx(base, 'irui-text-red-700');
    }
    return clsx(base, 'irui-text-newgray-800 dark:irui-text-white');
  }, [error, disabled]);
  return (
    <label
      {...props}
      className={clsx(labelClasses, !removeMargin && 'irui-mb-2.5', uppercased && 'irui-uppercase', props?.className)}
      htmlFor={props?.htmlFor ?? inputId ?? undefined}
      onClick={(e) => {
        inputRef?.current?.focus?.();
        props?.onClick?.(e);
      }}
    />
  );
};
