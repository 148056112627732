import { useMemo, createElement } from 'react';
import clsx from 'clsx';

import { TitleBaseProps } from './title-types';
import { useTextColorClasses } from '../hooks/useTextColorClasses';

export type TitleProps = React.HTMLAttributes<HTMLTitleElement> & TitleBaseProps;

export const Title = ({ className, variant = 'h1', color = 'default', ...props }: TitleProps) => {
  const variantClasses = useMemo(() => {
    return {
      h1: 'irui-text-4xl',
      h2: 'irui-text-3xl',
      h3: 'irui-text-2xl',
      h4: 'irui-text-xl',
      h5: 'irui-text-lg',
      h6: 'irui-text-base',
    };
  }, []);

  const colorClasses = useTextColorClasses(color);

  const titleElem = createElement(variant, {
    ...props,
    className: clsx(
      variantClasses[variant],
      colorClasses,
      'irui-font-sans-serif irui-transition irui-duration-500',
      className,
    ),
  });

  return titleElem;
};
