import clsx from 'clsx';
import { useState } from 'react';
import { HiChevronUp, HiOutlineChevronDown } from 'react-icons/hi';
import { animated, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';

import { Grid } from '../Grid';
import { Button, Row, Title, Toggle } from '../index';
import { AccordionBaseProps } from './types';

type AccordionProps = React.HTMLAttributes<HTMLDivElement> & AccordionBaseProps;

export const Accordion = ({
  defaultOpen = false,
  accordionTitle,
  withBorder = true,
  withToggle = false,
  onToggleClick,
  toggled,
  isOpen,
  onClick,
  children,
  headerProps,
  toggleProps,
  disableAccordion,
  ...props
}: AccordionProps) => {
  const [accordionOpen, setAccordionOpen] = useState(defaultOpen);
  const open = isOpen ? isOpen : accordionOpen;

  const [ref, { height: viewHeight }] = useMeasure();

  const { height, opacity } = useSpring({
    from: { height: 0, opacity: 0, y: 0 },
    to: {
      height: open ? viewHeight : 0,
      opacity: open ? 1 : 0,
      y: open ? 0 : 20,
    },
  });

  return (
    <div {...props} className={clsx('irui irui-flex irui-flex-col irui-w-full', props.className)}>
      <Grid
        {...headerProps}
        className={clsx(
          'irui-items-center sm:irui-py-8 irui-py-4 irui-gap-2 sm:irui-gap-4',
          withBorder && 'irui-border-t irui-border-newgray-300',
          headerProps?.className,
        )}
      >
        {withToggle && (
          <div className="irui-inline-flex irui-items-start">
            <Toggle
              toggled={toggleProps?.toggled ?? toggled}
              onClick={toggleProps?.onClick ?? onToggleClick}
              disabled={disableAccordion ?? toggleProps?.disabled}
              {...toggleProps}
            />
          </div>
        )}

        <Grid className="irui-flex-grow irui-justify-end irui-items-center">
          <Button
            fullwidth
            variant="tertiary"
            className="irui-flex irui-items-center irui-justify-center irui-text-base !p-0"
            onClick={() => (onClick ? onClick() : setAccordionOpen(!accordionOpen))}
            disabled={disableAccordion}
          >
            <Row className="irui-justify-between irui-items-center irui-flex-wrap">
              <Title variant="h5" className={clsx('irui-font-semibold', disableAccordion && 'irui-text-newgray-500')}>
                {accordionTitle}
              </Title>
              <div
                className={clsx(
                  'irui-inline-flex irui-items-center ml-auto',
                  disableAccordion && 'irui-text-newgray-500',
                )}
              >
                {open ? 'Show less' : 'Show more'}
                <div className="irui-flex irui-items-center irui-justify-center irui-w-8 irui-h-8 irui-bg-newgray-500 irui-rounded irui-ml-4">
                  {open ? <HiChevronUp size={16} color="white" /> : <HiOutlineChevronDown size={16} color="white" />}
                </div>
              </div>
            </Row>
          </Button>
        </Grid>
      </Grid>
      <animated.div
        style={{
          opacity,
          height: open ? 'auto' : height,
        }}
        className={clsx(!open && 'irui-overflow-hidden ')}
      >
        <div className="irui-flex irui-flex-wrap" ref={ref}>
          {children}
        </div>
      </animated.div>
    </div>
  );
};

export * from './SubAccordion';
