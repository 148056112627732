import { createFileRoute } from '@tanstack/react-router';
import { ErrorPage } from '@/pages/ErrorPage';
import { z } from 'zod';

export const Route = createFileRoute('/error')({
  component: () => <ErrorPage />,
  validateSearch: z.object({
    redirect: z.string().optional(),
    errorCode: z.string().optional(),
  }),
});
