import { addHours, getMinutes, getHours, getSeconds, differenceInSeconds } from 'date-fns';
import * as _ from 'lodash-es';

// https://gist.github.com/solenoid/1372386
export const generateObjectId = (): string => {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, () => {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};

export const resolveId = (object: string | Record<string, unknown>): string => {
  if (typeof object == 'string') return object;
  else if (object?.id) return object.id as string;
  else if (object?._id) return object._id as string;
  else throw Error('Could not resolve id');
};

export const normalizeId = <T>(object: Record<string, unknown>): T => {
  if (object?.id) return object as T;
  else if (object?._id) return { ...object, id: object._id } as T;
  else throw Error('Could not normalize id');
};

// the old classic
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Asserts that the given object is defined (not undefined or null). Throws error with given message otherwise.
export const assertDefined = <T>(argument: T | undefined | null, errorMsg?: string): T => {
  if (argument === undefined || argument === null) {
    throw new Error(errorMsg ?? `assertDefined failed, follow the stack trace`);
  }
  return argument as T;
};

// Helper function for calling functions after countdown
export const intervalFunction = (
  countdownFunction: (time: number) => void,
  endFunction: () => void,
  countdownTimeInSeconds = 10,
) => {
  countdownFunction(countdownTimeInSeconds);

  let countdownTime = countdownTimeInSeconds;
  const timer = setInterval(() => {
    countdownTime--;
    countdownFunction(countdownTime);

    if (countdownTime === 0) {
      clearInterval(timer);
      endFunction();
    }
  }, 1000);
};

// Helper function for calling functions after countdown
export const countDown = (
  countdownTimeInSeconds: number,
  everySecondFunction: (time: number) => void,
  doneFunction: () => void,
) => {
  everySecondFunction(countdownTimeInSeconds);

  let countdownTime = countdownTimeInSeconds;
  const timer = setInterval(() => {
    countdownTime--;
    everySecondFunction(countdownTime);

    if (countdownTime === 0) {
      clearInterval(timer);
      doneFunction();
    }
  }, 1000);
};

export const convertToDuration = (start: Date, end: Date): string => {
  const normalizeTime = (time: string): string => (time.length === 1 ? `0${time}` : time);

  const secondsAmount = differenceInSeconds(end, start);
  if (!secondsAmount) return '00:00:00'; // divide by 0 protection

  const SECONDS_TO_MILLISECONDS_COEFF = 1000;
  const MINUTES_IN_HOUR = 60;

  const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF;

  const date = new Date(milliseconds);
  const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR;
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)));
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)));
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)));

  // const hoursOutput = hours !== '00' ? `${hours}:` : '';

  return `${hours}:${minutes}:${seconds}`;
};

// TODO: what is this?
// export const replaceKeysDeep = (obj: any, keysMap: any) => {
//   // keysMap = { oldKey1: newKey1, oldKey2: newKey2, etc...
//   return _.transform(obj, function (result: any, value, key) {
//     // transform to a new object

//     const currentKey = keysMap[key] || key; // if the key is in keysMap use the replacement, if not use the original key

//     result[currentKey] = _.isObject(value) ? replaceKeysDeep(value, keysMap) : value; // if the key is an object run it through the inner function - replaceKeys
//   });
// };

export const normalizeIdProperty = (obj: any) => {
  return replaceKeysDeep(obj, { _id: 'id' });
};

// Recursive utility function to replace keys in an object
// This was created for transforming .id to ._id to match the old mongoose behavior
export const replaceKeysDeep = (val: any, keysMap: any): any => {
  if (val == null) return null;
  if (Array.isArray(val)) {
    return val.map((item) => replaceKeysDeep(item, keysMap));
  } else if (typeof val == 'object') {
    return Object.keys(val).reduce((obj: any, key) => {
      // Date is an object, but we don't want to modify Date, so exclude dates from the transformation
      if (val[key] instanceof Date) {
        return keysMap[val] || val;
      }
      const propKey = replaceKeysDeep(key, keysMap);
      const propVal = replaceKeysDeep(val[key], keysMap);
      obj[propKey] = propVal;
      return obj;
    }, {});
  } else if (typeof val === 'string') {
    return keysMap[val] || val;
  }
  return val;
};

/**
 * Deep diff between two object, using lodash
 */
export const deepDiffBetweenObjects = (object: Record<string, unknown>, base: Record<string, unknown>) => {
  const changes = (object: any, base: any) => {
    return _.transform(object, (result: any, value: any, key: string) => {
      if (!_.isEqual(value, base[key])) {
        if (_.isArray(value)) {
          result[key] = _.difference(value, base[key]);
        } else if (_.isObject(value) && _.isObject(base[key])) {
          result[key] = changes(value, base[key]);
        } else {
          result[key] = value;
        }
      }
    });
  };
  return changes(object, base);
};

const min2Digits = (num: number) => {
  const s = num.toString();
  if (s.length === 1) {
    return '0' + s;
  }
  return s;
};

/**
 * TODO: explain
 */
export const dateToMessageTimeText = (date?: Date): string => {
  if (!date) {
    date = new Date();
  }

  const isSameDay = (d1: Date, d2: Date): boolean => {
    return d1.getDate() == d2.getDate() && d1.getMonth() == d2.getMonth() && d1.getFullYear() == d2.getFullYear();
  };

  const isToday = isSameDay(date, new Date());
  if (isToday) {
    return min2Digits(date.getHours()) + ':' + min2Digits(date.getMinutes());
  }
  return min2Digits(date.getDate()) + '.' + min2Digits(date.getMonth() + 1) + '.' + date.getFullYear().toString();
};

export * from './zod-utils';
export * from './axios-client';
export * from './event-publicly-safe-selector';
export * from './all-available-teleconference-numbers';
