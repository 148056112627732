import clsx from 'clsx';
import { useMemo, useState } from 'react';

export type ToggleProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  label?: string;
  labelPosition?: 'left' | 'right';
  fullwidth?: boolean;
  toggled?: boolean | null;
  onClick?: (to: boolean) => void;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  togglePropsStyle?: {
    classNameEnabled?: string;
    classNameDisabled?: string;
  };
  large?: boolean;
};

export const Toggle = ({
  label,
  fullwidth,
  toggled,
  onClick,
  className,
  labelProps,
  disabled,
  labelPosition = 'left',
  large,
  togglePropsStyle,
  ...props
}: ToggleProps) => {
  const [toggledState, setToggledState] = useState(toggled ?? false);

  const toggledAbs = useMemo(() => {
    if (typeof toggled === 'boolean') {
      return toggled;
    }
    return toggledState;
  }, [toggledState, toggled]);

  const onToggle = () => {
    setToggledState(!toggledAbs);
    onClick?.(!toggledAbs);
  };

  const labelElem = (
    <label
      {...labelProps}
      className={clsx(
        disabled ? 'irui-text-newgray-400' : 'irui-text-newgray-900 dark:irui-text-white',
        large ? 'irui-text-lg irui-font-semibold' : 'irui-text-sm',
        labelPosition === 'left' && (large ? 'irui-mr-4' : 'irui-mr-3'),
        labelPosition === 'right' && (large ? 'irui-ml-3' : 'irui-ml-3'),
        'irui-font-sans-serif irui-transition irui-duration-500',
        labelProps?.className,
      )}
    >
      {label}
    </label>
  );

  return (
    <button
      {...props}
      type="button"
      className={clsx(
        'irui-inline-flex irui-items-center irui-outline-none focus:irui-outline-none irui-p-0 irui-text-left',
        {
          'irui-w-full irui-justify-between': fullwidth,
          'irui-w-auto irui-justify-start': !fullwidth,
        },
        className,
      )}
      onClick={onToggle}
      disabled={disabled}
    >
      {label && labelPosition === 'left' && labelElem}
      <div
        className={clsx(
          'toggle irui-relative irui-w-[48px] irui-min-w-[48px] irui-outline-none focus:irui-outline-none irui-p-0 irui-h-[26px] irui-border-none irui-rounded-[34px] irui-transition irui-duration-500',
          disabled
            ? 'irui-bg-newgray-300'
            : toggledAbs
              ? `irui-bg-newblue-500 dark:irui-bg-newgreen-400 ${togglePropsStyle?.classNameEnabled}`
              : `irui-bg-newgray-400 ${togglePropsStyle?.classNameDisabled}`,
        )}
      >
        <div
          className={clsx(
            'toggle-circle irui-absolute irui-rounded-full irui-h-[22px] irui-w-[22px] irui-top-[2px] irui-left-[2px] irui-right-0 irui-bottom-0 irui-transition irui-duration-500',
            disabled ? 'irui-bg-newgray-200' : 'irui-bg-white',
            {
              'irui-transform irui-translate-x-[22px]': toggledAbs,
            },
          )}
        />
      </div>
      {label && labelPosition === 'right' && labelElem}
    </button>
  );
};
