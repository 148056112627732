import clsx from 'clsx';
import { createElement } from 'react';

import { useTextColorClasses } from '../hooks/useTextColorClasses';
import { ButtonBaseProps, WithIconBaseProps } from './button-types';
import { useButtonClasses } from './useButtonClasses';

type ButtonWithIconProps = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonBaseProps & WithIconBaseProps;

export const ButtonWithIcon = ({
  children,
  className,
  variant = 'primary',
  fullwidth = false,
  icon,
  large = false,
  iconColor = 'default',
  iconProps,
  iconPosition = 'left',
  ...props
}: ButtonWithIconProps) => {
  const buttonClasses = useButtonClasses({
    large,
    variant,
    fullwidth,
    disabled: props?.disabled,
  });

  const iconColorClasses = useTextColorClasses(iconColor);

  const iconElem = createElement(icon, {
    ...iconProps,
    className: clsx(
      props?.disabled && 'irui-text-newgray-400',
      (variant === 'tertiary' || variant === 'outlined') && !props.disabled && iconColorClasses,
      iconProps?.className,
    ),
  });

  return (
    <button {...props} className={clsx(buttonClasses, className)}>
      <div className={clsx(large ? 'irui-gap-x-4' : 'irui-gap-x-3', 'irui-flex irui-items-center irui-flex-nowrap')}>
        {iconPosition === 'left' && iconElem}
        {children}
        {iconPosition === 'right' && iconElem}
      </div>
    </button>
  );
};
