import { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';

import { ColorPicker, ColorPickerProps } from '../ColorPicker';
import { TextInput, TextInputProps } from '../TextInput';

type TextInputWithColorPickerProps = {
  value?: string;
  onChange?: (value: string) => void;
  textInputProps?: TextInputProps;
  colorPickerProps?: ColorPickerProps;
};

export const TextInputWithColorPicker = ({
  value,
  onChange,
  textInputProps,
  colorPickerProps,
}: TextInputWithColorPickerProps) => {
  const [valueState, setValueState] = useState(value ?? '#111');

  const valueAbs = useMemo(() => {
    return value ?? valueState;
  }, [value, valueState]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setValueState(value);
      onChange?.(value);
      textInputProps?.onChange?.(e);
    },
    [onChange, setValueState, textInputProps],
  );

  const handleColorPickerChange = useCallback(
    (c: string) => {
      setValueState(c);
      onChange?.(c);
      colorPickerProps?.onValueChange?.(c);
    },
    [onChange, setValueState, colorPickerProps],
  );

  return (
    <TextInput
      {...textInputProps}
      value={valueAbs}
      onChange={handleInputChange}
      sideItem={
        <ColorPicker
          {...colorPickerProps}
          className={clsx(
            'irui-border-solid irui-border-gray-100',
            textInputProps?.sideItemPosition === 'left' ? 'irui-border-r' : 'irui-border-l',
          )}
          pickerOpenSide="left"
          value={valueAbs}
          onValueChange={handleColorPickerChange}
        />
      }
    />
  );
};
