import clsx from 'clsx';
import ReactSelect, {
  Props as ReactSelectProps,
  components,
  ControlProps,
  MultiValueProps,
  InputProps,
  ValueContainerProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
} from 'react-select';

import { Label, LabelProps } from '../Label';

const Control = ({ ...props }: ControlProps<unknown, true>) => {
  return (
    <components.Control
      {...props}
      className={clsx(
        '!irui-px-3 !irui-py-1 !irui-rounded-sm !irui-border-newgray-400 !irui-h-[52px]',
        props?.className,
      )}
    />
  );
};

const Input = ({ ...props }: InputProps) => {
  return <components.Input {...props} className={clsx(props.className)} />;
};

const ValueContainer = ({ ...props }: ValueContainerProps<unknown, true>) => {
  return <components.ValueContainer {...props} className={clsx('!irui-p-0', props.className)} />;
};

const MultiValue = ({ ...props }: MultiValueProps) => {
  return <components.MultiValue {...props} className={clsx('!irui-bg-white', props.className)} />;
};

const MultiValueContainer = ({ ...props }: MultiValueGenericProps) => {
  return (
    <components.MultiValueContainer
      {...props}
      innerProps={{
        ...props.innerProps,
        className: clsx(
          '!irui-rounded-full !irui-bg-white !irui-shadow irui-overflow-hidden !irui-py-[7px] !irui-pr-[5px] !irui-pl-[15px] !mr-3',
          props.innerProps?.className,
        ),
      }}
    />
  );
};

const MultiValueLabel = ({ ...props }: MultiValueGenericProps) => {
  return (
    <components.MultiValueLabel
      {...props}
      innerProps={{
        ...props.innerProps,
        className: clsx('!irui-p-0 irui-mr-1 irui-leading-4', props.innerProps?.className),
      }}
    />
  );
};

const MultiValueRemove = ({ ...props }: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove
      {...props}
      innerProps={{
        ...props.innerProps,
        className: clsx('irui-cursor-pointer', props.innerProps?.className),
      }}
    />
  );
};

export type SelectProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  selectProps?: ReactSelectProps;
  isMulti?: boolean;
  options?: unknown[];
  value?: unknown;
  onChange?: (value: any) => void;
  fullwidth?: boolean;
  label?: string;
  labelProps?: LabelProps;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
};

export const Select = ({
  fullwidth,
  selectProps,
  isMulti,
  options,
  value,
  onChange,
  label,
  labelProps,
  placeholder,
  disabled,
  error,
  ...props
}: SelectProps) => {
  return (
    <div
      {...props}
      className={clsx(
        'irui-inline-flex irui-flex-col irui-font-sans-serif',
        fullwidth && '!irui-w-full',
        props.className,
      )}
    >
      {(labelProps?.children || label) && (
        <Label
          {...labelProps}
          disabled={selectProps?.isDisabled ?? disabled}
          error={error}
          inputId={selectProps?.id ?? undefined}
        >
          {labelProps?.children ?? label}
        </Label>
      )}

      <ReactSelect
        {...selectProps}
        isDisabled={selectProps?.isDisabled ?? disabled}
        options={selectProps?.options ?? options}
        onChange={selectProps?.onChange ?? onChange}
        value={selectProps?.value ?? value}
        isMulti={selectProps?.isMulti ?? isMulti}
        theme={
          selectProps?.theme ??
          ((theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#111111',
              primary75: '#008D82',
              primary50: '#00B0A2',
              primary25: '#74C9C2',
            },
          }))
        }
        placeholder={selectProps?.placeholder ?? placeholder ?? ''}
        noOptionsMessage={selectProps?.noOptionsMessage ?? (() => '...')}
        components={{
          Control,
          // IndicatorSeparator,
          // DropdownIndicator,
          // IndicatorsContainer,
          MultiValue,
          MultiValueContainer,
          MultiValueLabel,
          MultiValueRemove,
          Input,
          ValueContainer,
        }}
        className={clsx('!irui-p-0', selectProps?.className)}
      />
    </div>
  );
};
