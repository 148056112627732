import type { AppRouter } from '@inderes/videosync-api/src/trpc/router';
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';

export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;

export const trpcUrl = (import.meta.env.BACKSTAGE_API_URL as string) + '/trpc';

export const resolveTrpcHeaders = (sessionId: string) => {
  const authorizationToken = Cookies.get('jwt');
  const token = sessionStorage.getItem('webStudioToken');
  return {
    authorization: `Bearer ${authorizationToken}`,
    'x-web-studio-token': `Bearer ${token}`,

    'x-request-id': nanoid(),
    'x-session-id': sessionId,
  };
};

/**
 * We want to make two kinds of clients for tRPC: One with react-query attached (@trpc/react) and one without (@trpc/client).
 */

export const trpc = createTRPCReact<AppRouter>({});

export const rawTrpc = (sessionId: string) =>
  createTRPCProxyClient<AppRouter>({
    links: [
      httpBatchLink({
        url: trpcUrl,
        headers: {
          ...resolveTrpcHeaders(sessionId),
        },
      }),
    ],
  });
