import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';

export type TooltipProps = React.HTMLAttributes<HTMLDivElement> & {
  tooltipText?: React.ReactNode;
  tooltipMaxWidth?: string;
  tooltipProps?: React.HTMLAttributes<HTMLDivElement>;
  tooltipArrowProps?: React.HTMLAttributes<HTMLDivElement>;
  tooltipArrowWrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  tooltipPosition?: 'left' | 'right' | 'top' | 'bottom';
  tooltipVisible?: boolean;
};

export const Tooltip = ({
  tooltipMaxWidth,
  tooltipText,
  tooltipProps,
  tooltipArrowProps,
  tooltipArrowWrapperProps,
  children,
  tooltipVisible,
  tooltipPosition = 'top',
  ...props
}: TooltipProps) => {
  const tooltipContainerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [hovering, setHovering] = useState(false);
  const [tooltipClickedOpen, setTooltipClickedOpen] = useState(false);

  const visibleAbs = useMemo(() => {
    return tooltipVisible ?? (hovering || tooltipClickedOpen);
  }, [tooltipVisible, hovering, tooltipClickedOpen]);

  const [tooltipWidth, setTooltipWidth] = useState(0);
  const [tooltipHeight, setTooltipHeight] = useState(0);

  useEffect(() => {
    const rect = tooltipRef?.current?.getBoundingClientRect();
    if (rect) {
      setTooltipWidth(rect?.width);
      setTooltipHeight(rect?.height);
    }
  }, [tooltipRef, setTooltipWidth, visibleAbs]);

  const tooltipStyle = useMemo<React.CSSProperties>(() => {
    switch (tooltipPosition) {
      case 'top':
        return {
          left: '50%',
          bottom: 'calc(100% + 1rem)',
          marginLeft: (-tooltipWidth / 2).toString() + 'px',
        };
      case 'bottom':
        return {
          left: '50%',
          top: 'calc(100% + 1rem)',
          marginLeft: (-tooltipWidth / 2).toString() + 'px',
        };
      case 'left':
        return {
          top: '50%',
          right: 'calc(100% + 1rem)',
          marginTop: (-tooltipHeight / 2).toString() + 'px',
        };
      case 'right':
        return {
          top: '50%',
          left: 'calc(100% + 1rem)',
          marginTop: (-tooltipHeight / 2).toString() + 'px',
        };
      default:
        return {};
    }
  }, [tooltipPosition, tooltipHeight, tooltipWidth]);

  const tooltipArrowWrapperStyle = useMemo<React.CSSProperties>(() => {
    switch (tooltipPosition) {
      case 'top':
        return {
          bottom: 'calc(100% + 0.5rem)',
          left: 'calc(50% - 0.5rem)',
        };
      case 'bottom':
        return {
          top: 'calc(100% + 0.5rem)',
          left: 'calc(50% - 0.5rem)',
        };
      case 'left':
        return {
          top: 'calc(50% - 0.5rem)',
          right: 'calc(100% + 0.5rem)',
        };
      case 'right':
        return {
          top: 'calc(50% - 0.5rem)',
          left: 'calc(100% + 0.5rem)',
        };
      default:
        return {};
    }
  }, [tooltipPosition]);

  const tooltipArrowStyle = useMemo<React.CSSProperties>(() => {
    switch (tooltipPosition) {
      case 'top':
        return {
          clipPath: 'polygon(140% -50%, -50% 140%, 120% 120%)',
        };
      case 'bottom':
        return {
          clipPath: 'polygon(150% -40%, -40% 150%, -80% -80%)',
        };
      case 'left':
        return {
          clipPath: 'polygon(120% 125%, -30% -20%, 200% -100%)',
        };
      case 'right':
        return {
          clipPath: 'polygon(125% 120%, -20% -30%, -100% 200%)',
        };
      default:
        return {};
    }
  }, [tooltipPosition]);

  return (
    <div
      {...props}
      ref={tooltipContainerRef}
      onMouseEnter={(e) => {
        props.onMouseEnter?.(e);
        setHovering(true);
      }}
      onMouseLeave={(e) => {
        props.onMouseLeave?.(e);
        setHovering(false);
      }}
      onClick={(e) => {
        props.onClick?.(e);
        if (tooltipClickedOpen) {
          setHovering(false);
        }
        setTooltipClickedOpen(!tooltipClickedOpen);
      }}
      className={clsx('irui-relative irui-cursor-pointer', props.className)}
    >
      {children}
      <div
        {...tooltipProps}
        ref={tooltipRef}
        onClick={(e) => e.stopPropagation()}
        style={{
          width: 'max-content',
          ...(tooltipMaxWidth ? { maxWidth: tooltipMaxWidth } : {}),
          ...tooltipStyle,
          ...tooltipProps?.style,
        }}
        className={clsx(
          'irui-transition irui-duration-500 irui-ease-in-out irui-absolute irui-font-sans-serif irui-p-4 irui-shadow irui-bg-white dark:irui-bg-black irui-text-newgray-900 dark:irui-text-white',
          visibleAbs ? 'irui-opacity-100 irui-visible irui-z-40' : 'irui-opacity-0 irui-invisible irui-z-0',
          tooltipProps?.className,
        )}
      >
        {tooltipProps?.children ?? tooltipText}
      </div>
      <div
        {...tooltipArrowWrapperProps}
        style={{
          ...tooltipArrowWrapperStyle,
          ...tooltipArrowWrapperProps?.style,
        }}
        className={clsx(
          'irui-transition irui-duration-500 irui-ease-in-out irui-absolute irui-w-4 irui-h-4 irui-transform irui-rotate-45',
          visibleAbs ? 'irui-opacity-100 irui-visible irui-z-50' : 'irui-opacity-0 irui-invisible irui-z-0',
          tooltipArrowWrapperProps?.className,
        )}
      >
        <div
          {...tooltipArrowProps}
          style={{
            ...tooltipArrowStyle,
            ...tooltipArrowProps?.style,
          }}
          className={clsx('irui-bg-white dark:irui-bg-black irui-shadow irui-w-full irui-h-full', tooltipArrowProps?.className)}
        />
      </div>
    </div>
  );
};
