import { UseMonthProps } from '@datepicker-react/hooks';
import clsx from 'clsx';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import { useDatepickerContext } from './DatepickerContext';
import { Month } from './Month';

export type CalendarProps = React.HTMLAttributes<HTMLDivElement> & {
  useMonthProps?: Partial<UseMonthProps>;
};

export const Calendar = ({ useMonthProps, ...props }: CalendarProps) => {
  const { activeMonths, firstDayOfWeek, goToNextMonths, goToPreviousMonths } = useDatepickerContext();
  return (
    <div {...props} className={clsx('irui-flex irui-gap-x-3 irui-p-3 irui-relative', props.className)}>
      <button
        className="irui-absolute irui-top-3 irui-left-3 irui-text-newgray-900 dark:irui-text-white"
        onClick={goToPreviousMonths}
      >
        <HiChevronLeft />
      </button>
      {activeMonths.map((m) => (
        <Month
          key={`${m.year}-${m.month}`}
          year={m.year}
          month={m.month}
          firstDayOfWeek={firstDayOfWeek}
          useMonthProps={useMonthProps}
        />
      ))}
      <button
        className="irui-absolute irui-top-3 irui-right-3 irui-text-newgray-900 dark:irui-text-white"
        onClick={goToNextMonths}
      >
        <HiChevronRight />
      </button>
    </div>
  );
};
