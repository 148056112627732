import clsx from 'clsx';

import { useButtonClasses } from './useButtonClasses';
import { ButtonBaseProps } from './button-types';
import { LoaderSpinner } from '../LoaderSpinner';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonBaseProps;

export const Button = ({
  children,
  className,
  variant = 'primary',
  fullwidth = false,
  large = false,
  loading = false,
  spinnerSize = 'md',
  spinnerClassName,
  ...props
}: ButtonProps) => {
  const buttonClasses = useButtonClasses({
    large,
    variant,
    fullwidth,
    disabled: props?.disabled,
  });

  return (
    <button {...props} className={clsx(buttonClasses, className)}>
      {loading && <LoaderSpinner size={spinnerSize} className={spinnerClassName} />}
      {children}
    </button>
  );
};
