import clsx from 'clsx';
import { useMemo } from 'react';

type TextProps = React.HTMLAttributes<HTMLParagraphElement> & {
  variant?: 'body' | 'body-2' | 'small';
};

export const Text = ({ variant = 'body', className, ...props }: TextProps) => {
  const variantClasses = useMemo(() => {
    return {
      body: 'irui-text-base',
      'body-2': 'irui-text-sm',
      small: 'irui-text-xs',
    };
  }, []);
  return (
    <p
      className={clsx(
        variantClasses[variant],
        'irui-text-newgray-900 dark:irui-text-white irui-font-sans-serif irui-transition irui-duration-500',
        className,
      )}
      {...props}
    />
  );
};
