import { createElement } from 'react';
import clsx from 'clsx';
import { useButtonClasses } from './useButtonClasses';

import { ButtonBaseProps, WithIconBaseProps } from './button-types';
import { useTextColorClasses } from '../hooks/useTextColorClasses';

type LinkButtonWithIconProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & ButtonBaseProps & WithIconBaseProps;

export const LinkButtonWithIcon = ({
  children,
  className,
  variant = 'primary',
  fullwidth = false,
  iconColor = 'default',
  large = false,
  icon,
  iconProps,
  disabled = false,
  iconPosition = 'left',
  ...props
}: LinkButtonWithIconProps) => {
  const buttonClasses = useButtonClasses({
    large,
    variant,
    fullwidth,
  });
  const iconColorClasses = useTextColorClasses(iconColor);

  const iconElem = createElement(icon, {
    ...iconProps,
    className: clsx((variant === 'tertiary' || variant === 'outlined') && iconColorClasses, iconProps?.className),
  });
  return (
    <a
      {...props}
      className={clsx(
        buttonClasses,
        'irui-inline-flex irui-items-center irui-justify-center',
        disabled && 'irui-pointer-events-none irui-cursor-default irui-opacity-50',
        className,
      )}
    >
      <div
        className={clsx(
          large ? 'irui-gap-x-3' : 'irui-gap-x-2',
          'irui-flex irui-items-center irui-flex-nowrap irui-w-full',
        )}
      >
        {iconPosition === 'left' && iconElem}
        {children}
        {iconPosition === 'right' && iconElem}
      </div>
    </a>
  );
};
