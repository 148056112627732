import { useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { GoKebabHorizontal } from 'react-icons/go';
import { animated } from 'react-spring';

import { useHeightAnimation } from '../hooks/useHeightAnimation';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

type KebabMenuProps = React.HTMLAttributes<HTMLDivElement> & {
  open?: boolean;
  onToggleClick?: (to: boolean) => void;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  iconDirection?: 'vertical' | 'horizontal';
  menuOpenSide?: 'left' | 'right';
  contentContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  contentWrapperProps?: React.HTMLAttributes<HTMLDivElement>;
};

export const KebabMenu = ({
  contentContainerProps,
  contentWrapperProps,
  buttonProps,
  open,
  onToggleClick,
  iconDirection = 'vertical',
  menuOpenSide = 'left',
  children,
  ...props
}: KebabMenuProps) => {
  const [openState, setOpenState] = useState(open ?? false);

  const wrapperRef = useRef(null);

  const onClickOutside = useCallback(() => {
    setOpenState(false);
    onToggleClick?.(false);
  }, [onToggleClick, setOpenState]);

  useOnClickOutside(wrapperRef, onClickOutside);

  const openAbs = useMemo(() => {
    return open ?? openState;
  }, [open, openState]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setOpenState(!openAbs);
      buttonProps?.onClick?.(e);
      onToggleClick?.(!openAbs);
    },
    [setOpenState, buttonProps, onToggleClick, openAbs],
  );

  const { expand, contentRef } = useHeightAnimation(openAbs, { duration: 200 });
  const icon = useMemo(() => {
    switch (iconDirection) {
      case 'horizontal':
        return <GoKebabHorizontal />;
      case 'vertical':
        return <GoKebabHorizontal style={{ transform: 'rotate(90deg)' }} />;
    }
  }, [iconDirection]);
  return (
    <div {...props} ref={wrapperRef} className={clsx('irui-relative', props.className)}>
      <button
        {...buttonProps}
        className={clsx('irui-cursor-pointer irui-text-newgray-900 dark:irui-text-white', buttonProps?.className)}
        onClick={handleClick}
      >
        {icon}
      </button>
      <animated.div
        {...contentWrapperProps}
        style={{ ...expand, ...contentWrapperProps?.style }}
        className={clsx(
          menuOpenSide === 'right' ? 'irui-left-0' : 'irui-right-0',
          'irui-absolute irui-top-[24px] irui-overflow-hidden irui-min-w-[248px] irui-shadow-lg irui-rounded irui-z-50',
          contentWrapperProps?.className,
        )}
      >
        <div
          {...contentContainerProps}
          ref={contentRef}
          className={clsx(
            'irui-bg-white irui-text-newgray-900 dark:irui-bg-black dark:irui-text-white',
            contentContainerProps?.className,
          )}
        >
          {children}
        </div>
      </animated.div>
    </div>
  );
};
