import clsx from 'clsx';
import { useCallback, useMemo, useRef, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { CgRadioChecked, CgRadioCheck } from 'react-icons/cg';
type RadioProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'onClick'> & {
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  value?: string;
  checked?: boolean;
  label?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  radioIconContainerProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  radioIconProps?: IconBaseProps;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  disabled?: boolean;
  labelPosition?: 'left' | 'right';
};

export const Radio = ({
  label,
  inputProps,
  radioIconContainerProps,
  radioIconProps,
  labelProps,
  name,
  onChange,
  onClick,
  value,
  checked,
  disabled,
  labelPosition = 'left',
  ...props
}: RadioProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [checkedState, setCheckedState] = useState(checked ?? inputProps?.checked ?? false);

  const checkedAbs = useMemo(() => {
    if (typeof inputProps?.checked === 'boolean') {
      return inputProps.checked;
    }
    if (typeof checked === 'boolean') {
      return checked;
    }
    return checkedState;
  }, [checked, inputProps?.checked, checkedState]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedState(!checkedAbs);
      onChange?.(e);
      inputProps?.onChange?.(e);
    },
    [checkedAbs, onChange, inputProps],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      setCheckedState(!checkedAbs);
      onClick?.(e);
      inputProps?.onClick?.(e);
    },
    [checkedAbs, onClick, inputProps],
  );

  const disabledAbs = useMemo(() => {
    return disabled || inputProps?.disabled || radioIconContainerProps?.disabled;
  }, [disabled, inputProps?.disabled, radioIconContainerProps?.disabled]);

  const wrapperClasses = useMemo(() => {
    return 'irui-inline-flex irui-items-center';
  }, []);

  const labelClasses = useMemo(() => {
    const base = clsx(
      'irui-text-sm irui-font-sans-serif',
      disabledAbs ? 'irui-text-newgray-400' : 'irui-text-newgray-800 dark:irui-text-white irui-cursor-pointer',
    );

    if (labelPosition === 'left') {
      return clsx(base, 'irui-mr-2 irui-order-1');
    }

    if (labelPosition === 'right') {
      return clsx(base, 'irui-ml-2 irui-order-3');
    }
    return clsx(base, 'irui-ml-2 irui-order-1');
  }, [labelPosition, disabledAbs]);

  const inputClasses = useMemo(() => {
    return 'irui-hidden';
  }, []);

  const iconContainerClasses = useMemo(() => {
    return clsx(disabledAbs && 'irui-cursor-default', 'irui-outline-none focus:irui-outline-none irui-order-2');
  }, [disabledAbs]);

  const iconClasses = useMemo(() => {
    return clsx(
      'dark:irui-text-white irui-w-6 irui-h-6',
      checkedAbs ? 'irui-text-newblue-400' : 'irui-text-newgray-400',
      disabledAbs && 'irui-text-newgray-400',
    );
  }, [disabledAbs, checkedAbs]);

  return (
    <div {...props} className={clsx(wrapperClasses, props?.className)}>
      {(labelProps?.children || label) && (
        <label
          {...labelProps}
          className={clsx(labelClasses, labelProps?.className)}
          onClick={(e) => {
            if (labelProps?.htmlFor !== inputRef?.current?.id) {
              inputRef?.current?.click();
            }
            labelProps?.onClick?.(e);
          }}
        >
          {labelProps?.children ?? label}
        </label>
      )}
      <button
        {...radioIconContainerProps}
        type="button"
        onClick={(e) => {
          inputRef?.current?.click();
          radioIconContainerProps?.onClick?.(e);
        }}
        className={clsx(iconContainerClasses, radioIconContainerProps?.className)}
        disabled={radioIconContainerProps?.disabled ?? inputProps?.disabled ?? disabled ?? false}
      >
        {checkedAbs ? (
          <CgRadioChecked {...radioIconProps} className={clsx(iconClasses, radioIconProps?.className)} />
        ) : (
          <CgRadioCheck {...radioIconProps} className={clsx(iconClasses, radioIconProps?.className)} />
        )}
      </button>
      <input
        {...inputProps}
        disabled={inputProps?.disabled ?? radioIconContainerProps?.disabled ?? disabled ?? false}
        className={clsx(inputClasses, inputProps?.className)}
        value={value ?? inputProps?.value ?? undefined}
        name={name ?? inputProps?.name ?? undefined}
        checked={checkedAbs}
        ref={inputRef}
        onClick={handleClick}
        onChange={handleChange}
        type="radio"
      />
    </div>
  );
};
